<template>
  

  <div class="row content-padding pd-30-0 bg-white">
    <!-- The Modal -->
<div class="modal" id="eligibilty">
  <div class="modal-dialog modal-lg" style="font-size:16px">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Eligibility of a Business Loan</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
       <div class="container-fluid">
    <div class="row bg-blue px-10">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-sm-8 col-8 d-flex justify-content-start align-items-center">
            <h3 class="heading color-white roboto-bold banner-heading">
              Eligibility of a Business Loan
            </h3>
          </div>
          <div class="col-md-4 col-sm-4 col-4 banner-image-section d-flex justify-content-end">
            <!-- <img src="../../assets/img/eligibility-banner.png" /> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="container">
        <h3 class="heading mgt-30">
          There are different eligibility criteria for self employed loan product from various banks. 
          Few common eligibility criteria are as below:-
        </h3>
         <div class="mgb-30 col-md-6 list-container flex-column d-flex justify-content-center">
                    <ul class="list feature-list">
                        <li class="list-heading">Age limit:25 to 65 years
                        </li>
                     <li class="list-heading">Employment: Proprietorship , Partnership and Pvt Ltd Company</li>
                     <li class="list-heading">Best Offer to Choose From 20+ Banks and NBFCs</li>
                     <li class="list-heading">Business Vintage of atleast 3 years</li>
                     <li class="list-heading">Annual Sales: Atleast Rs. 40 lacs per annum</li>
                     <li class="list-heading">Credit Score above 650</li>
                     <li class="list-heading">APR Ranges from 12.99% to 28% p.a</li>
                     <li class="list-heading">Repayment Tenure ranges from 1 yrs to 4yrs</li>
                    </ul>
                </div>
        <!-- <div class="row mgt-30 mgb-30">
          <table class="table table-bordered">
            <thead>
              <tr class="bg-blue color-white">
                <th>Categories</th>
                <th>Eligibility</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Age</td>
                <td>25 to 65 years</td>
              </tr>
              <tr>
                <td>Employment</td>
                <td>Proprietorship , Partnership and Pvt Ltd Company</td>
              </tr>
              <tr>
                <td>Business Vintage</td>
                <td>of atleast 3 years</td>
              </tr>
              <tr>
                <td>Annual Sales</td>
                <td>Rs. 50 lacs per annum</td>
              </tr>
              <tr>
                <td>APR Ranges</td>
                <td>from 12.99% to 28% p.a</td>
              </tr>
              <tr>
                <td>Repayment Tenure ranges</td>
                <td>from 1 yrs to 4yrs</td>
              </tr>
              <tr>
                <td>Credit Score</td>
                <td>Above 650</td>
              </tr>
            </tbody>
          </table>
        </div> -->
      </div>
    </div>
    <!-- <div class="row">
      <Footer />
    </div> -->
  </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>


<div class="modal" id="feature">
  <div class="modal-dialog modal-lg" style="font-size:16px">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Features and Benefits</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
       <div class="container-fluid">
    <div class="row bg-blue px-10">
      <div class="container">
          <div class="row">
        <div class="col-md-8 col-sm-8 col-8 d-flex justify-content-start align-items-center">
          <h3 class="heading color-white roboto-bold banner-heading">Some Key Benefits of Business Loans</h3>
        </div>
        <div class="col-md-4 col-sm-4 col-4 banner-image-section d-flex justify-content-end">
          <!-- <img class='img' src="../../assets/img/benefits-banner.png" />  -->
        </div>
          </div>
      </div>
    </div>
    <div class="row bg-white">
        <div class="container">
            <div class="row">
                <div class="col-md-12 mgt-15">
                <h3 class="heading">Get to Choose the Best Lender for Your Business Loan Needs</h3>
                <p class="sub-heading">
                  A business loan is one of the best remedies of meeting all financial concerns arising in our business for both expansion as well as 
                  general dayto day hurdles . Today almost everyone come across cash crunch situationthat 
                  requires lumpsum funds at the moment, while one can gradually pay them over the period of time.
                   These requirement differs from maintaining healthy cash flow ,so as to make payments to the suppliers on time or to expand theirbusinessby buying new or upgrading
                    machinery or equipment, etc.               </p>
                 <p class="sub-heading">
                  While there are several ways to get access to funds, asking friends and 
                  family may be a risky proportion. Thus the best bet is to opt for business loan from financial institutions without any collateral.
                </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mgt-10">
                    <h3 class="heading">Features and Benefits</h3>
                </div>
                
                <div class="mgb-30 col-md-6 list-container flex-column d-flex justify-content-center pt-5">
                    <ul class="list feature-list">
                        <li class="list-heading">Loan Upto 50 Lacs
                        </li>
                     <li class="list-heading">Digital Documentation</li>
                     <li class="list-heading">Best Offer to Choose From 20+ Banks and NBFCs</li>
                     <li class="list-heading">Loans Jagat Service is Absolutely Free of Cost for their Customers</li>
                     <li class="list-heading">Best Deal Guranteed</li>
                     <li class="list-heading">No Collatoral or Gurantor Needed</li>
                     <li class="list-heading">Dedicated Loan Professional For Profile Based Accessment</li>
                     <li class="list-heading">Highest Apporval Ratio</li>
                     <li class="list-heading">Maximum Loan Eligibity Offers</li>
                     <li class="list-heading">Cashback upto RS 5000 On Disbursal of Your Loan</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="row">
      <Footer />
    </div> -->
  </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>

<div class="modal" id="document_req">
  <div class="modal-dialog modal-lg" style="font-size:16px">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title"> Business Loan - Document Required</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
       <div class="container-fluid">
    <div class="row bg-blue px-10">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-sm-8 col-8 d-flex justify-content-start align-items-center">
            <h3 class="heading color-white roboto-bold banner-heading">
              Document Requirement For Business Loan
            </h3>
          </div>
          <div class="col-md-4 col-sm-4 col-4 banner-image-section d-flex justify-content-end">
            <!-- <img src="../../assets/img/document-banner.png" /> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row bg-white">
      <div class="container">
        <div class="row">
          <div class="col-md-12 mgt-15">
            <h3 class="heading">Business Loan - Document Required</h3>
            <p class="sub-heading">
              The lender seek following documents from the business loan applicants. 
              These documents can be submitted either as hard copy by physically visiting the bank or by simple emailing the documents or uploading the scanned copy on the digital platform/website .
            </p>
          </div>
        </div>
        <div class="row">
          
        
          <div
            class="col-md-6 list-container flex-column d-flex justify-content-center pt-5"
          >
            <ul class="list feature-list">
              <li class="list-heading">KYC Documents</li>
              <li class="list-heading">Business Existance Proof</li>
              <li class="list-heading">Current Address Proof</li>
              <li class="list-heading">Last Two Year ITRs</li>
              <li class="list-heading">Latest Bank Statements</li>
            </ul>
            
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <Footer />
    </div> -->
  </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>
    <div
      class=" other-page col-md-2 col-sm-4 col-xl-4 d-flex flex-column align-items-center"
    >
    <a href="#"  class="pagelink d-flex flex-column justify-content-center align-items-center" data-toggle="modal" data-target="#feature">
      <!-- <router-link
        class="pagelink d-flex flex-column justify-content-center align-items-center"
        v-bind:to="`/${page}/benefits`"
        
      > -->
        <img src="../../assets/img/benefits@2x.png" alt="Benifits" />
        <p class="mgt-15 color-black sub-heading">Features and Benefits</p>
      <!-- </router-link> -->
    </a>
    </div>
    <div
      class=" other-page col-md-8 col-sm-4 col-xl-4 d-flex flex-column align-items-center"
    >
        <a href="#"  class="pagelink d-flex flex-column justify-content-center align-items-center" data-toggle="modal" data-target="#eligibilty">

      <!-- <router-link
        class="pagelink d-flex flex-column justify-content-center align-items-center"
        v-bind:to="`/${page}/eligibility`"
      > -->
        <img src="../../assets/img/eligibility.png" alt="Eligibility" />
        <p class="mgt-15 color-black sub-heading">Eligibility</p>
        </a>
      <!-- </router-link> -->
    </div>
    <div
      class="other-page col-md-2 col-sm-4 col-xl-4 d-flex flex-column align-items-center"
    >
        <a href="#"  class="pagelink d-flex flex-column justify-content-center align-items-center" data-toggle="modal" data-target="#document_req">

      <!-- <router-link
        class="pagelink d-flex flex-column justify-content-center align-items-center"
        v-bind:to="`/${page}/document-requirements`"
      > -->
        <img
          src="../../assets/img/document-required.png"
          alt="Document Required"
        />
        <p class="mgt-15 color-black sub-heading">Document Required</p>
        </a>
      <!-- </router-link> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "OtherPagesBL",
  components: {},
  props: ["page"],
};
</script>

<style scoped>
.col-md-2 img,
.col-md-8 img {
  background-color: #fff;
  padding: 10px;
  width: 70px;
  box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 0.4);
}
</style>

<template>
<div class="container-fluid">
  <Header />
<div v-if='this.loader.page' class="load d-flex justify-content-center align-items-center">
  <div class="spinner-border text-primary" role="status">
  <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
  </div>
</div>

<div v-else class='row contai'>

<div class='container containz'>


  <div class='text_container color_white'>
    <p class='big-heading d-flex justify-content-center pdt-60'>Congratulations! You Are Eligible for Personal Loan Offers </p>
    <p class='heading_two pdb-30'>
      Adjust the loan amount and choose tenure to suit the EMI paying capacity.
    </p>
  </div>

  <div class='container_two shadow mr-auto ml-auto'>
    <div class='first_container'>
      <div style="clear:both;"></div>
    <div class="mblFilterBox mob">
		<div class="mblFilterLoanAmt">Loan Amount (<i class="fa fa-inr"></i>)
		<span id="loan_demo">₹{{filter}}</span></div>
		<div class="mblFilterTenure">Tenure <span id="range_demo">{{tenure}} Years</span> </div>
		<div class="mblFilterButton"><a id="mblFilterButtonIcon" href="javascript:void(0)">Modify <i class="fa fa-filter"></i></a></div>
 	</div>
   <div class="row mblslider">
      <div class='col-md-12 col-sm-12 col-12'>
             <p class='padd_text' style="font-weight:600">Loan Amount:</p>
            <input type='range' :min='min_amt' :max='max_filter' value='0' class='button-blue' :step="10000" v-model='filter' v-on:change='filter_post' style="width:100%!important">
          </div>
          <div class='col-md-12 col-sm-12 col-12'>
             <p class='padd_text' style="font-weight:600">Tennure:</p>
            <input type='range' min='1' :max='5' value='0' class='button-blue' v-model='tenure' v-on:change='filter_post' style="width:100%!important">
          </div>
      </div>
      <div class='slider_container row web'>
        <!-- <div class='col-md-12 d-flex flex-row justify-content-between align-items-center'> -->
          <span class='col-md-4   pad_top d-flex flex-row align-items-center'>
            <p class='padd_text'>Loan Amount:    </p>
            <input type='range' :min='min_loan_amt' :max='max_filter' value='0' class='button-blue' :step="10000" v-model='filter' v-on:change='filter_post'>
            <p class='padd_text padd_left'>₹{{filter}}   </p>

          </span>
          <span class='col-md-4  pad_top d-flex flex-row align-items-center '>
                <p class='padd_text'>Tenure:    </p>
                <input type='range' min='1' :max='5' value='0' class=' button-blue' v-model='tenure' v-on:change='filter_post'>
                <p class='padd_text padd_left'>{{tenure}}Years   </p>
              <div>
              </div>
            </span>
        <span class='pad_top btm_top'  v-if='list.length > 0'>
          <span class="blinking maxLoanAmt"> Max. Loan Amount - Rs <span v-html="max_loan_amt"></span> Lacs</span>
          <p class='total_result'><span>  We have </span>{{list.length}} Bank Results</p>
        </span>
        <!-- </div> -->
      </div>
    </div>
<div class="container mob">
  <div class="row" v-if='!loader.table'  v-for="(item,index) in list" v-bind:key="item.id">
    <div class="col-12 col-sm-8 col-md-6 col-lg-4 pt-3">
      <hr>
            <div class="card" style="margin-bottom:12px">
            <div class="card-header">
           						<img :src="'/img/bank/' + item.bank_logo" v-bind:alt=" item.bank_name" >
            </div>
            <div class="card-body">
            <h4 class="card-title"></h4>

            

            <table class="table">
  <thead>
    <tr>
      <th scope="col">Rate</th>
      <th scope="col">EMI</th>
      <th scope="col">Fees</th>
      <th scope="col">Amount</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">	
       	<div  v-if="item.special_intrest!=0">
							<div style='color:white;text-decoration:line-through'> <i class="fa fa-rupee"></i> {{item.intrest}}%</div> <br>
							<i class="fa fa-rupee"></i> {{item.special_intrest}}
					</div>
					<div  v-else>
						<i class="fa fa-rupee"></i> {{item.intrest}}% 
					</div>
          </th>
      <td>
        <div  v-if="item.special_emi!=0"> 
						<div style='color:white;text-decoration:line-through'> <i class="fa fa-rupee"></i> {{formatNumber(item.blemi)}}</div><br>
						<i class="fa fa-rupee"></i> {{formatNumber(item.special_emi)}} 
					</div>
					<div  v-else> 
						<i class="fa fa-rupee"></i> {{formatNumber(item.blemi)}}
					</div>
      
      </td>
      <td>
        	<div  v-if="item.special_fees!=0"> 
						<div style='color:white;text-decoration:line-through'> <i class="fa fa-rupee"></i> {{formatNumber(item.process_fees)}}</div><br>
						<i class="fa fa-rupee"></i> {{formatNumber(item.special_fees)}}
					</div>
					<div   v-else> 
						<i class="fa fa-rupee"></i> {{formatNumber(item.process_fees)}}<br>
					</div>
      </td>
      <td><i class="fa fa-rupee"></i>{{formatNumber(list[0].loan_amt)}} </td>
    </tr>
    
  </tbody>
</table>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-6">
                </div>
                <div class="col-md-6">
                
                <a class="applyLink btn btn-primary blink_me" href="javascript:void(0)" v-on:click='apply_loan(index)'>Apply&nbsp;&nbsp;<i class="fa fa-bolt"></i></a>
                </div>
              </div>
            </div>
            </div>
    </div>
  </div>
</div>
    <div class='table_container web'>
      <div  class='tbl'>

        <div class="rsltTableHeadPL" v-if='list.length > 0'>
				<div class="rsltTableHeadPLbank">Bank</div>
				<!-- <div class="rsltTableHeadPLloanAmt">Max Loan</div> -->
				<div class="rsltTableHeadPLloanAmt">Loan Amount</div>
				<div class="rsltTableHeadPLIntRate">Interest Rate</div>
				<div class="rsltTableHeadPLProFee">Processing Fees
					<a href="#" class="field-tip">
						<i class="fa fa-question-circle-o"></i>
						<div class="tip-content">This is a one time fee charged by Bank &amp; is deducted directly from your loan amount on disbursement.</div>
					</a>
				</div>
				<div class="rsltTableHeadPLEMI">Monthly EMI</div>
				<div class="rsltTableHeadPLtenure">Tenure
					<a href="#" class="field-tip">
						<i class="fa fa-question-circle-o"></i>
						<div class="tip-content">Tenure is on yearly basis.</div>
					</a>
				</div>
				<div class="rsltTableHeadPLaction">Action</div>
			</div>
      <div class="bankListMain">
				<div v-if='loader.table' class=" ">
                  <div class='d-flex justify-content-center align-items-center'>


                    <div class="mx-auto spinner-border text-primary" role="status">
                    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                  </div>
                  </div>
              </div>
				<div class="outerLoader" style="text-align:center" v-else-if='list.length==0'>
					<div class="alert alert-primary" role="alert" style="font-size:15px">
						<h2><span>No Result Found</span> </h2>
					<p>We searched hard! But couldn’t find any results</p>	
					</div>
					
					<img src='/img/no-result.jpg'>
				</div>
				<div  class="bankListInner" v-for="(item,index) in list" v-bind:key="item.id">
					<div class="rsltTblListPLbank">
						<img :src="'/img/bank/' + item.bank_logo" v-bind:alt=" item.bank_name" >
					</div>
					<!-- <div class="rsltTblListPLloanAmt"> <i class="fa fa-rupee"></i> {{item.minloan}} </div> -->
					<div class="rsltTblListPLloanAmt"> <i class="fa fa-rupee"></i> {{formatNumber(list[0].loan_amt)}} </div>
					
					<div class="rsltTblListPLIntRate"  v-if="item.special_intrest!=0">
						<del> <i class="fa fa-rupee"></i> {{item.intrest}}%</del> <br>
							<i class="fa fa-rupee"></i> {{item.special_intrest}}
						<span>% Rate</span>
					</div>
					<div class="rsltTblListPLIntRate"  v-else>
						<i class="fa fa-rupee"></i> {{item.intrest}}% 
						<span>% Rate</span>
					</div>
					<div class="rsltTblListPLProFee" v-if="item.special_fees!=0"> 
						<del><i class="fa fa-rupee"></i> {{formatNumber(item.process_fees)}}</del><br>
						<i class="fa fa-rupee"></i> {{formatNumber(item.special_fees)}}
					</div>
					<div class="rsltTblListPLProFee"  v-else> 
						<i class="fa fa-rupee"></i> {{formatNumber(item.process_fees)}}<br>
					</div>
					<div class="rsltTblListPLEMI" v-if="item.special_emi!=0"> 
						<del><i class="fa fa-rupee"></i> {{formatNumber(item.blemi)}}</del> <span>Monthly EMI</span> <br>
						<i class="fa fa-rupee"></i> {{formatNumber(item.special_emi)}} <span>Monthly EMI</span>
					</div>
					<div class="rsltTblListPLEMI" v-else> 
						<i class="fa fa-rupee"></i> {{formatNumber(item.blemi)}}<span>Monthly EMI</span> 
					</div>
					<div class="rsltTblListPLtenure">{{item.label_value}}</div>
					<div class="rsltTblListPLaction">
						<a class="applyLink" href="javascript:void(0)" @click="apply_loan(index)">Apply&nbsp;&nbsp;<i class="fa fa-bolt"></i></a>
					</div>
				</div>
			</div>

        <!-- <table class="table">
          <thead>
            <tr class='head'>
              <th class='border_col' scope="col">Bank</th>
              <th class='border_col' scope="col">Loan Amount</th>
              <th class='border_col' scope="col">Interest Rate</th>
              <th class='border_col' scope="col">Processing Fees</th>
              <th class='border_col' scope="col">Monthly EMI</th>
              <th class='border_col' scope="col">Bal. t/f to other bank</th>
              <th class='border_col' scope="col">Tenure</th>
              <th class='border_col' scope="col">Action</th>

            </tr>
          </thead>
          <tbody>
            <tr v-if='loader.table' class="  ">
              <td colspan="7"  >
                <div class='d-flex justify-content-center align-items-center'>
                  <div class="mx-auto spinner-border text-primary" role="status">
                  <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                </div>
                </div>
              </td >
            </tr>
            <tr v-else-if='list.length==0' class="">
              <td colspan="7"  >
                <div class='d-flex justify-content-center align-items-center'>
                  <p >Sorry no result found</p>
                </div>
              </td >
            </tr>

            <tr  v-else-if='!loader.table' v-for= '(i,index) in list' v-bind:key='index'>
              <td class='col_' >
                <img :src="'/img/bank/' + i.bank_logo" v-bind:alt=" i.bank_name" >
              </td>
              <td  class='col_'>₹{{i.loan_amt}}</td>
              <td  class='col_'>{{i.intrest}}%</td>
              <td  class='col_'>₹{{i.process_fees}}</td>
              <td  class='col_'>₹{{i.blemi}}</td>
              <td  v-if='i.bal_t_or_f_to_other_bank !=null' class='col_'>Upto {{i.bal_t_or_f_to_other_bank}}% of outstanding Loan</td>
              <td v-else  class='col_'>
              </td>
              <td  class='col_'>{{i.label_value.split(" ")[0]}} Year</td>
              <td v-if='loader.btn &&loader.index==index' class="  d-flex justify-content-center align-items-center">
                <div class="spinner-border text-primary" role="status">
                <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                </div>
              </td>
              <td  v-else class='end_col d-flex justify-content-center align-items-center'>

                <button type="button" class="btn button-blue d-flex justify-content-center align-items-center color-white bg-blue"
                 v-on:click='apply_loan(index)'>Apply</button>
              </td>


            </tr>

          </tbody>
        </table> -->

      </div>

    </div>

  </div>
</div>
</div>
</div>
</template>

<script>
import axios from '../../../axios-auth'
import Header from '../../sub-components/Header';

// import {this.self_employed_form} from '../globalVariableSalaried.js'

export default{
  name:'home',
  props:{

  },
  data(){
    return {
      self_employed_form:localStorage.getItem("self_employed_form") ? JSON.parse(localStorage.getItem("self_employed_form")) : {},
      filter:JSON.parse(localStorage.getItem("self_employed_form")).loan_amount_required ,
      max_filter:JSON.parse(localStorage.getItem("self_employed_form")).loan_amount_required,
      min_loan_amt:100000,
      max_loan_amt : '',
  list:[],
      tenure:'4',
      loader:{
        page:false,
        btn:false,
        table:false ,
        apply_filters:false,
      },
      request_id:0,


    }
  },

  methods:{
    apply_loan(index){
      
      localStorage.removeItem('b_response_id');
      this.loader.index=index;
      //console.log('called')
      this.loader.btn=true;

      axios.post('/applied-business-loan',
      {
        'request_id':this.request_id,
         roi:this.list[index].intrest,
  			 pf:this.list[index].process_fees,
  			 emi:this.list[index].blemi,
  			 bank_id:this.list[index].bank_id,
  			bank_name:this.list[index].bank_name,
  			loan_amount:this.list[index].loan_amt,
        tennure:this.tenure
      }
      )
      .then((response)=>{//console.log(response);
        // this.request_id=response.data.id;
      this.loader.btn=false;
      this.$router.push({ name: 'apply-result',
              params: {
                    applocation_no: response.data.application_no,
                    name:response.data.name,
                    type:'Business Loan'
                  }});
      })
      .catch((err)=>{
        //console.log(err);
      this.loader.btn=false;
      })
    },
    filter_post(){
      this.loader.apply_filters=true;
      this.loader.table=true;
      axios.get('/business-loan-filter/'+localStorage.getItem('b_response_id')+'/'+this.filter+'/'+this.tenure      )
      .then((response)=>{
        //console.log('response',response,response.data.length>13?13:response.data.length);
         this.list = response.data

            this.loader.table=false;
            if(response.data.length > 0){
            var maix_data = this.findWinConfirmed(response.data);
            if(parseInt(this.self_employed_form.loan_amount_required) > parseInt(this.max_filter)){
    					var max = parseInt(this.self_employed_form.loan_amount_required)
    					maix_data = parseInt(this.self_employed_form.loan_amount_required);
    				}else{
    					max = maix_data
    				}
    				var mx_amt = maix_data / 100000

            this.max_filter = maix_data.toFixed(2)
            //console.log('this.max_filter',this.max_filter);

             this.max_loan_amt = mx_amt.toFixed(2) 
            }
           //.slice(0,response.data>13?13:response.data.length);
            this.loader.table=false;
      })
      .catch((err)=>{
        //console.log('error',err);
        this.list=[];
        this.loader.table=false;
      this.loader.apply_filters=false;
      })
    },
    get_image_link(img){
      //return require('../../assets/img/bank/'+img)
    },
    findWinConfirmed(topConfirmed) {
      let findTop = [];
      topConfirmed.forEach(obj => findTop.push(obj.minloan));
      return Math.max(...findTop);
  		},
      formatNumber(num) {
			var n1, n2;
			num = num + '' || '';
			// works for integer and floating as well
			n1 = num.split('.');
			n2 = n1[1] || null;
			n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
			num = n2 ? n1 + '.' + n2 : n1;
			return num;
		},
  },

  components: {
    Header,
    },

mounted(){
    // this.self_employed_form=localStorage.getItem("self_employed_form") ? JSON.parse(localStorage.getItem("self_employed_form")) : {};
    axios.put('/business-loan/'+localStorage.getItem('b_response_id'),{
      ...this.self_employed_form
    })
    .then((response)=>{//console.log('then-POST     -',response);
      this.request_id = response.data.id;
      this.loader.page=false;
      //console.log(this.loader.page);
      axios.put('/business-loan/'+this.request_id,{
      ...this.self_employed_form
        })
        .then((response)=>{
          //console.log(response);
          //response
          // this.request_id=response.data.id;
          axios.get('/business-loan-filter/'+this.request_id+'/'+this.filter+'/'+this.tenure)
            .then((response)=>{
            this.list = response.data

            this.loader.table=false;
            var maix_data = this.findWinConfirmed(response.data);
            if(parseInt(this.self_employed_form.loan_amount_required) > parseInt(this.max_filter)){
    					var max = parseInt(this.self_employed_form.loan_amount_required)
    					maix_data = parseInt(this.self_employed_form.loan_amount_required);
    				}else{
    					max = maix_data
    				}
    				var mx_amt = maix_data / 100000

            this.max_filter = maix_data.toFixed(2)
            //console.log('this.max_filter',this.max_filter);

             this.max_loan_amt = mx_amt.toFixed(2) 

           //.slice(0,response.data>13?13:response.data.length);
            this.loader.table=false;

            //console.log(this.loader.table,this.list.length);
            })
            .catch((err)=>{
              //console.log('error',err);
            this.loader.table=false;
            this.list=[]
            })

        })
        .catch((err)=>{
          //console.log('err-POST     -',err);
        this.loader.table=false;
        this.list=[]
        })


    })
    .catch((err)=>{
      //console.log(err);
    this.loader.page=false;
    })

},

}

</script>
<style scoped>
.contai{
  min-height:calc(100vh - 59px);
  background-color:#0255AB;


  /* position: fixed; */
}
.color_white{
  color:white;

}

.heading_one{
margin-top:5rem;
font-size: 2.5rem;
font-weight: 600;
text-align: center;
}
.heading_two{
margin-top:2rem;
font-size: 24px;
font-weight: 100;
text-align: center;
}
.containz{
  margin-bottom:2rem;
}
.container_two{
  margin-top:4rem;
  background-color:#fff;
  min-height:22rem;
  border-radius: 5px;
  max-width:100%;
  /* padding-left:1rem;
  padding-right:1rem; */
  padding-top:0.01rem;
}
.first_container{
  padding-left:1rem;
  padding-right:1rem;
}
.slider_container{
  /* height:7rem; */
  /* border-bottom:1px solid #B3B3B3; */
 margin-top:2.5rem;
}
.dropdown{
  border-radius: 5px;
  /* height:2.1rem; */
  /* width:4rem; */
  padding:0.7rem;
}
.padd_text{
  padding-right:1rem;
  font-size: 2em;
  font-weight: normal;
}
.btn_big{
  height:3rem;
  width:7rem;
}
.padd_left{
  padding-left:0.7rem;
}
.table_container{

overflow:auto;
/* border : 1px solid #000; */
}
.tbl{
  min-width:690px;
}
.result_size{
  margin-top:1.5rem;
  padding-top:1rem;
  padding-bottom:1rem;
  font-size: 1rem;
}

.border{
  border-style: groove;
    border-width: 0px!important;
    border-color: coral;

}
.head{
  margin-top:1rem!important;
  margin-bottom:1rem;
  padding-bottom:1rem;
  font-size: 1rem;
}
.border_col{

  background-color:#034E9A;
  color:#fff;
  font-size: 1.3em;
  font-weight: normal;

}
.tbody{
  padding:2rem!important;
  border-top:1px solid #000;
  font-size:1rem;
}

.col_{
    border-top:0px solid #000;
    border-right:1px solid #000;
}
.end_col{
  border-top:0px solid #000;
}
.bank_img{
  width:10rem;
  height:auto;
}
@media screen and (max-width: 1000px ) and (min-width:768px) {
  .padd_text{
    font-size: 1.5em;
  }
}
@media screen and (max-width: 767px) {
  .heading_one{
  margin-top:5rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  }
  .heading_two{
  margin-top:2rem;
  font-size: 1rem;
  font-weight: 100;
  text-align: center;
  }
.pad_top{
  padding-top:.5rem;
}
.bank_img{
  width:5rem;
  height:auto;
}
.tbody{
  padding:2rem!important;
  border-top:1px solid #000;
  font-size:0.8rem;
}
.head{
  margin-top:1rem!important;
  margin-bottom:1rem;
  padding-bottom:1rem;
  font-size: 0.8rem;
}
.btm_top{
  margin-left:1rem;
}
}
del {
 color:red !important;
}
.outerLoader{width: 100%; float:left; text-align: center;}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) 
{
  .mblslider{
    display: block i !important;
  }
  .webslider_1{
    display: none;
  }
    .mob{
      display: block  !important;
    }
    .web{
      display: none !important;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .mblslider{
    display: block !important;
  }
  .webslider_1{
    display: none !important;
  }
.mob{
      display: block !important;
    }
    .web{
      display: none !important;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .mblslider{
    display: block !important;
  }
  .webslider_1{
    display: none !important;
  }
.mob{
      display: block !important;
    }
    .web{
      display: none !important;
      
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
.mblslider{
    display: none !important;
  }
  .webslider_1{
    display: block !important;
  }
  .mob{
      display: none !important;
    }
    .web{
      display: flex  !important;
      width:100%;
       float: none;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .mblslider{
    display: none !important;
  }
  .webslider_1{
    display: block !important;
  }
  .mob{
      display: none !important;
    }
    .web{
      display: flex  !important;
      width:100%;
       float: none;
    }
}
.blink_me {
    animation: blinker 1s linear infinite;
    font-weight: 700;
    font-size: 2rem;
    float: right;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.table{
  font-size: 12px !important;
}
.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgb(23 20 20 / 3%);
    /* border-bottom: 1px solid rgba(0,0,0,.125); */
}
.card{
      background-color: #243046!important;
      color:white !important;
}
.card-footer {
    padding: .75rem 1.25rem;
    /* background-color: rgba(0,0,0,.03); */
    border-top: 1px solid white;
}
hr {
  border:none;
  border-top:1px dashed #0255AB;
  color:#fff;
  background-color:#fff;
  height:1px;
  width:100%;
}
</style>

<template>
<div class="container-fluid">
  
    <Header />
<div v-if='loader.page' class="load d-flex justify-content-center align-items-center">
  <div class="spinner-border text-primary" role="status">
  <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
  </div>
</div>

<div v-else class='row contai'>

<div class='container containz'>
  
  <div class='text_container color_white'>
    <p class='big-heading d-flex justify-content-center pdt-60'>Congratulations! You Are Eligible for Personal Loan Offers </p>
    <p class='heading_two'>
      Adjust the loan amount and choose tenure to suit the EMI paying capacity.
    </p>
  </div>

  <div class='container_two shadow mr-auto ml-auto'>
    <div class='first_container'>
 
  <div style="clear:both;"></div>
    <div class="mblFilterBox mob">
		<div class="mblFilterLoanAmt">Loan Amount (<i class="fa fa-inr"></i>)
		<span id="loan_demo">₹{{filter}}</span></div>
		<div class="mblFilterTenure">Tenure <span id="range_demo">{{tenure}} Years</span> </div>
		<div class="mblFilterButton"><a id="mblFilterButtonIcon" href="javascript:void(0)">Modify <i class="fa fa-filter"></i></a></div>
 	</div>
   <div class="row mblslider">
      <div class='col-md-12 col-sm-12 col-12'>
             <p class='padd_text' style="font-weight:600">Loan Amount:</p>
            <input type='range' :min='min_amt' :max='max_filter' value='0' class='button-blue' :step="10000" v-model='filter' v-on:change='filter_post' style="width:100%!important">
          </div>
          <div class='col-md-12 col-sm-12 col-12'>
             <p class='padd_text' style="font-weight:600">Tennure:</p>
            <input type='range' min='1' :max='maximum_tennure' value='0' class='button-blue' v-model='tenure' v-on:change='filter_post' style="width:100%!important">
          </div>
      </div>
      <div class='slider_container row'>
      
        <!-- <div class='col-md-12 d-flex flex-row justify-content-between align-items-center'> -->
          <div class='col-md-4 col-sm-12 col-12 pad_top d-flex flex-row align-items-center web wrapper-table'>
            <p class='padd_text'>Loan Amount:    </p>
            <input type='range' :min='min_amt' :max='max_filter' value='0' class='button-blue' :step="10000" v-model='filter' v-on:change='filter_post'>
            <p class='padd_text padd_left'>₹{{filter}}   </p>
          </div>
          <div class='col-md-4 col-12 col-sm-12 pad_top d-flex flex-row align-items-center web'>
                <p class='padd_text'>Tenure: </p>
                <input type='range' min='1' :max='maximum_tennure' value='0' class='button-blue' v-model='tenure' v-on:change='filter_post'>
                <p class='padd_text padd_left'>{{tenure}}Years   </p>

              <div>
              </div>
            </div>
        <span class='pad_top btm_top' v-if='list.length > 0'>
          <span class="blinking maxLoanAmt">Eligible Loan Amount - Rs <span v-html="loan_max_filter"></span> Lacs</span>
          <p class=' total_result'><span >We have </span>{{list.length}} Bank Results</p>
        </span>
        <!-- </div> -->
      </div>
    </div>
    <div class="container mob">
  <div class="row" v-if='!loader.table'  v-for="(item,index) in list" v-bind:key="item.id">
    <div class="col-12 col-sm-8 col-md-6 col-lg-4 pt-3">
      <hr>
            <div class="card" style="margin-bottom:12px">
            <div class="card-header">
           				<img :src="'/img/bank/' + item.bank_logo" v-bind:alt=" item.bank_name" >
            </div>
            <div class="card-body">
            <h4 class="card-title"></h4>

            <table class="table">
  <thead>
    <tr>
      <th scope="col">Rate</th>
      <th scope="col">EMI</th>
      <th scope="col">Fees</th>
      <th scope="col">Amount</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">	
        <div  v-if="item.special_roi!=null">
          <div style='color:white;text-decoration:line-through'>
          <div style='color:white'>{{item.roi}} %</div>
          </div>
          <br>
						{{item.special_roi}} %
					</div>
          <div v-else>
						{{item.roi}}% 
					</div>
          </th>
      <td>
        	<div  v-if="item.special_emi!=0"> 
            	<div style='color:white;text-decoration:line-through'>
                <div style='color:white'><i class="fa fa-rupee"></i> {{formatNumber(item.special_emi)}}</div>
</div>
						<br><i class="fa fa-rupee"></i> {{formatNumber(item.emi)}} 
					</div>
					<div v-else> 
						<i class="fa fa-rupee"></i> {{formatNumber(item.emi)}} 
					</div>
      </td>
      <td>
        	<div  v-if="item.special_process_fees!=0"> 
						<div style='color:white;text-decoration:line-through'>
  <div style='color:white'><i class="fa fa-rupee"></i> {{formatNumber(item.process_fees)}}</div>
</div>
           <br> <i class="fa fa-rupee"></i>{{formatNumber(item.special_process_fees)}} 
					</div>
					<div v-else> 
						<i class="fa fa-rupee"></i> {{formatNumber(item.process_fees)}} 
					</div>
      </td>
      <td><i class="fa fa-rupee"></i> <span v-html="set_loan_amount"> {{formatNumber(item.loan_amt)}}</span></td>
    </tr>
    
  </tbody>
</table>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-6">
                </div>
                <div class="col-md-6">
                <a class="applyLink btn btn-primary blink_me" v-if="isClicked==false"  href="javascript:void(0)" v-on:click='apply_loan(index)'>Apply&nbsp;&nbsp;<i class="fa fa-bolt"></i></a>
                <vue-simple-spinner size="medium" v-else  />

                </div>
              </div>
            </div>
            </div>
    </div>
  </div>
</div>
    <div class='table_container web'>

      <div class='tbl'>

        	<div class="rsltTableHeadPL" v-if='list.length > 0' >
				<div class="rsltTableHeadPLbank">Bank</div>
				<!-- <div class="rsltTableHeadPLloanAmt">Max Loan</div> -->
				<div class="rsltTableHeadPLloanAmt">Loan Amount</div>
				<div class="rsltTableHeadPLIntRate">Interest Rate</div>
				<div class="rsltTableHeadPLProFee">Processing Fees
					<a  class="field-tip">
						<i class="fa fa-question-circle-o"></i>
						<div class="tip-content">This is a one time fee charged by Lender &amp; is deducted directly from your loan amount at the time of final disbursement</div>
					</a>
				</div>
				<div class="rsltTableHeadPLEMI">Monthly EMI</div>
				<div class="rsltTableHeadPLtenure">Tenure
					<a  class="field-tip">
						<i class="fa fa-question-circle-o"></i>
						<div class="tip-content">Tenure is on yearly basis.</div>
					</a>
				</div>
				<div class="rsltTableHeadPLaction">Action</div>
			</div>
<div class="bankListMain">
  

          <div v-if='loader.table' class="">
                  <div class='d-flex justify-content-center align-items-center'>
                    <div class="mx-auto spinner-border text-primary" role="status">
                    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                  </div>
                  </div>
              </div>
				<div class="outerLoader" v-else-if='list.length==0 || list=="0"'>
					<div class="alert alert-primary" role="alert" style="font-size:15px">
						<h2><span>No Result Found</span> </h2>
					<p>We searched hard! But couldn’t find any results</p>	
					</div>
					<img src='/img/no-result.jpg'>
				</div>
        <!-- Mobile View-->
        
        
        <!-- Web View -->
				<div  class="bankListInner" v-else-if='!loader.table'  v-for="(item,index) in list" v-bind:key="item.id">
					<div class="rsltTblListPLbank">
						<img :src="'/img/bank/' + item.bank_logo" v-bind:alt=" item.bank_name" >
					</div>
					<!-- <div class="rsltTblListPLloanAmt"> <i class="fa fa-rupee"></i> {{item.minloan}} </div> -->
					<div class="rsltTblListPLloanAmt"> <i class="fa fa-rupee"></i> <span v-html="set_loan_amount"> {{formatNumber(item.loan_amt)}}</span> </div>
					<div class="rsltTblListPLIntRate" v-if="item.special_roi!=null">
          <div style='color:red;text-decoration:line-through'>
          <div style='color:black'>{{item.roi}} %</div>
          </div>
          <br>
						{{item.special_roi}} %
						<span>% Rate</span>
					</div>
					<div class="rsltTblListPLIntRate" v-else>
						{{item.roi}}% 
						<span>% Rate</span>
					</div>
					<div class="rsltTblListPLProFee" v-if="item.special_process_fees!=0"> 
						<div style='color:red;text-decoration:line-through'>
  <div style='color:black'><i class="fa fa-rupee"></i> {{formatNumber(item.process_fees)}}</div>
</div>
           <br> <i class="fa fa-rupee"></i>{{formatNumber(item.special_process_fees)}} <span>Processing Fees</span>
					</div>
					<div class="rsltTblListPLProFee" v-else> 
						<i class="fa fa-rupee"></i> {{formatNumber(item.process_fees)}} <span>Processing Fees</span>
					</div>
					<div class="rsltTblListPLEMI" v-if="item.special_emi!=0"> 
            	<div style='color:red;text-decoration:line-through'>
  <div style='color:black'><i class="fa fa-rupee"></i> {{formatNumber(item.special_emi)}}</div>
</div>
						<br><i class="fa fa-rupee"></i> {{formatNumber(item.emi)}}<span>Monthly EMI</span> 
					</div>
					<div class="rsltTblListPLEMI" v-else> 
						<i class="fa fa-rupee"></i> {{formatNumber(item.emi)}}<span>Monthly EMI</span> 
					</div>
					<div class="rsltTblListPLtenure">{{item.tenure}}</div>
					<div class="rsltTblListPLaction">

						<a class="applyLink" v-if="isClicked==false" href="javascript:void(0)" v-on:click='apply_loan(index)'>Apply&nbsp;&nbsp;<i class="fa fa-bolt"></i></a>
            <vue-simple-spinner size="medium" v-else  />

					</div>
				</div>
			</div>
        
      </div>

    </div>

  </div>
</div>
</div>
</div>
</template>
<script>
import VueSimpleSpinner from 'vue-simple-spinner'

import axios from '../../../axios-auth'
import Header from '../../sub-components/Header';

export default{
  name:'home',
  props:{

  },
  mounted ()
  {
    
    this.conversion();

     if(localStorage.getItem("name")==null){
           this.$router.push('/salaried/transaction-detail');
    }
    axios.put("/personal-loan/"+localStorage.getItem("response_id"),{...this.salariedObj })
    .then((res) =>
    {
      //console.log(res);
      this.request_id=res.data.id
      axios.put('/personal-loan/'+this.request_id,{
      ...this.salariedObj
        })
        .then((response)=>{console.log(this.salariedObj);
          // this.request_id=response.data.id;
          this.loader.page = false;
          this.response = response.data
          this.search_data.company_id = this.salariedObj.current_company_name;
         // this.search_data.tenure = this.tenure
          axios.post('/personal-loan-result',this.search_data)
            .then((response)=>{
           
            this.loader.table=false;
            var maix_data = this.findWinConfirmed(response.data);
            this.tenure = response.data[0].max_tennure;
            this.maximum_tennure = response.data[0].max_tennure
            if(parseInt(localStorage.getItem("loan_amount_required")) > parseInt(maix_data)){
                this.filter = maix_data
                
    					//var max = parseInt(localStorage.getItem("loan_amount_required"))
    					//maix_data = parseInt(localStorage.getItem("loan_amount_required"));
    				}else{
               this.filter = localStorage.getItem("loan_amount_required")
    					//max = maix_data
    				}
    				var mx_amt = maix_data/100000
            this.max_filter = maix_data.toFixed(2)
            this.set_loan_amount = this.formatNumber(this.filter)
            //console.log('this.max_filter',this.max_filter);
            this.list = response.data;
            this.loan_max_filter = mx_amt.toFixed(2)
            //console.log('loader is false ',this.loader.table,this.list);
            })
            .catch((err)=>{//console.log('error',err);
            this.loader.table=false;
            this.list = []
            })

        })
        .catch((err)=>{//console.log('err-POST     -',err);
        this.loader.page=false;
        this.list=[]
        })
    })
    .catch((err) =>
    {
      //console.log(err);
      this.loader.page=false;
    })

  },
  methods:{
    conversion () {
			this.$gtag.event('conversion', { send_to: 'AW-309981734/t6iOCNX9zs8DEKbk55MB' })
		  },
    formatNumber(num) {
			var n1, n2;
			num = num + '' || '';
			// works for integer and floating as well
			n1 = num.split('.');
			n2 = n1[1] || null;
			n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
			num = n2 ? n1 + '.' + n2 : n1;
			return num;
		},
    apply_loan(index){
      this.loader.index = index;
      //console.log('called');
      this.loader.btn = true;
      this.isClicked = true
      let pf = this.list[index].pf.replace("%", "")

      axios.post('/applied-personal-loan',{
          request_id:this.request_id,
          roi:this.list[index].roi,
  				pf:pf,
  				emi:this.list[index].emi,
  				bank_id:this.list[index].bank_id,
  				bank_name:this.list[index].bank_name,
  				loan_amount:this.list[index].loan_amt,
          tennure:this.tenure
      }).then((response)=>{//console.log(response);
        //this.request_id=response.data.id;
        this.loader.btn=false;
        this.isClicked = false
        localStorage.removeItem("response_id");
        localStorage.setItem("req_id",this.request_id)
        window.location.href = "apply_consent_thank/"
      })
      .catch((err)=>{//console.log('ERROR',err);
        this.loader.btn=false;
      })
    },
    filter_post(){
      this.loader.apply_filter = true;
      this.loader.table = true;
      this.search_data.company_id = this.salariedObj.current_company_name;
      this.search_data.tenure = this.tenure
      this.search_data.loan_amount_required = this.filter
      axios.post('/personal-loan-result',this.search_data)
        .then((response)=>{//console.log(response);
         this.list = response.data;
          this.loader.table = false;
        this.loader.apply_filter = false;
        if(this.list.length > 0){
      
        this.max_filter = this.findWinConfirmed(response.data);
        var maix_data = this.max_filter

        if(parseInt(this.filter) > parseInt(this.max_filter)){
           this.filter = this.max_filter
        }

        var mx_amt = maix_data / 100000
        this.max_filter = maix_data.toFixed(2)
        this.set_loan_amount = this.formatNumber(this.filter)
            //console.log('this.max_filter',this.max_filter);
        this.loan_max_filter = mx_amt.toFixed(2)

        this.set_loan_amount = this.formatNumber(this.filter)
        //console.log('loader is false ',this.loader.table,this.list);
        }
        })
        .catch((err)=>{//console.log('error',err);
        this.loader.table = false;
        this.loader.apply_filter = false;
        this.list=[]
        })
    },
    get_image_link(img){
     // return require('../../assets/img/bank/'+img)
    },
    findWinConfirmed(topConfirmed) {
  			let findTop = -1;
  			topConfirmed.forEach(obj => {if (findTop <obj.minloan){findTop=obj.minloan}});
  			return findTop;
  		},

       findWinTennureConfirmed(topConfirmed) {
  			let findTop = -1;
  			topConfirmed.forEach(obj => {if (findTop <obj.duration){findTop=obj.duration}});
  			return findTop;
  		},

  },
  data(){
    return {
      isClicked:false,
      loan_max_filter:'',
       set_loan_amount:'',
       min_amt:100000,
       maximum_tennure:5,
       loader:{
         page:true,
         btn:false,
         table:true,
         apply_filter:false,
       },
       request_id:null,
       tensure:null,
       filter:localStorage.getItem("loan_amount_required"),
       // filter:self_employed_form.loan_amount_required,
       max_filter:localStorage.getItem("loan_amount_required"),
       list:[],
       tenure:'5',
       salariedObj:{
         "current_city_other":'',//
         "loan_amount_required":localStorage.getItem("loan_amount_required"),//
         "mobile_number":localStorage.getItem("mobile_number"),//
         "net_salary_all_deductions":localStorage.getItem("net_salary_all_deductions"),//
         "any_loans_running_emi_monthly":localStorage.getItem("any_loans_running_emi_monthly"),//
         "civil_score":localStorage.getItem("civil_score"),///
         "company_type":"",
         "current_city_of_residence":localStorage.getItem("current_city_other"),//
         "current_company_name":localStorage.getItem("current_company_no")==''?'':localStorage.getItem("current_company_no")*1,///
         "date_of_birth":localStorage.getItem("date_of_birth"),///
         "email":localStorage.getItem("email"),///
         "joining_date_in_current_company":localStorage.getItem("joining_date_in_current_company"),///
         "mode_of_salary":localStorage.getItem("mode_of_salary"),///
         "name":localStorage.getItem("name"),///
         "other_company_name":localStorage.getItem("current_company_name"),///
         "panno":localStorage.getItem("panno"),//
         "salary_account_name":localStorage.getItem("salary_account_name")==''?'':localStorage.getItem("salary_account_name")*1,
         "total_work_experience":'3-5',///
         "residence_ownership":localStorage.getItem("ownership"),
        "source":localStorage.getItem('source')
       },
      post_data:{
        "current_city_other":localStorage.getItem("current_city_other"),//
        "loan_amount_required":localStorage.getItem("loan_amount_required"),//
        "mobile_number":localStorage.getItem("mobile_number"),//
        "net_salary_all_deductions":localStorage.getItem("net_salary_all_deductions"),//
      },
      response:{},
      search_data:{
                  "any_loans_running_emi_monthly": localStorage.getItem("any_loans_running_emi_monthly"),
                  "civil_score": localStorage.getItem("civil_score"),
                  "company_id": null,
                  "company_type": "",
                  "loan_amount_required": localStorage.getItem("loan_amount_required"),
                  "mode_of_salary": localStorage.getItem("mode_of_salary"),
                  "net_salary_all_deductions": localStorage.getItem("net_salary_all_deductions"),
                  "tenure": this.tenure,
                  "total_work_experience": localStorage.getItem("total_work_experience")
                },
    }

  },

  components: {
    Header,
    VueSimpleSpinner
  },
}
</script>
<style scoped>
.contai{
  min-height:calc(100vh - 59px);
  background-color:#0255AB;
  /* position: fixed; */
}
.color_white{
  color:white;
}

.heading_one{
margin-top:5rem;
font-size: 2.5rem;
font-weight: 600;
text-align: center;
}
.heading_two{
margin-top:2rem;
font-size: 24px;
font-weight: 100;
text-align: center;
}
.containz{
  margin-bottom:2rem;
}
.container_two{
  margin-top:4rem;
  background-color:#fff;
  min-height:22rem;
  border-radius: 5px;
  max-width:100%;
  /* padding-left:1rem;
  padding-right:1rem; */
  padding-top:0.01rem;
}
.first_container{
  padding-left:1rem;
  padding-right:1rem;
}
.slider_container{
  /* height:7rem; */
  /* border-bottom:1px solid #B3B3B3; */
 margin-top:2.5rem;
}
.dropdown{
  border-radius: 5px;
  /* height:2.1rem; */
  /* width:4rem; */
  padding:0.7rem;
}
.padd_text{
  padding-right:1rem;
  font-size: 2em;
  font-weight: normal;
}
.btn_big{
  height:3rem;
  width:7rem;
}
.padd_left{
  padding-left:0.7rem;
}
.table_container{

overflow:auto;
/* border : 1px solid #000; */
}
.tbl{
  min-width:100% !important;
}
.result_size{
  margin-top:1.5rem;
  /* border-top:1px solid  #B3B3B3; */
  padding-top:1rem;
  padding-bottom:1rem;
  font-size: 1rem;
}

.border{
  border-style: groove;
    border-width: 0px!important;
    border-color: coral;

}
.head{
  margin-top:1rem!important;
  margin-bottom:1rem;
  padding-bottom:1rem;
  font-size: 1rem;
}
.border_col{

  background-color:#034E9A;
  color:#fff;
  font-size: 1.3em;
  font-weight: normal;

}
.tbody{
  padding:2rem!important;
  border-top:1px solid #000;
  font-size:1rem;
}

.col_{
    border-top:0px solid #000;
    border-right:1px solid #000;
}
.end_col{
  border-top:0px solid #000;
}
.bank_img{
  width:10rem;
  height:auto;
}
@media screen and (max-width: 1000px ) and (min-width:768px) {
  .padd_text{
    font-size: 1.5em;
  }
}
@media screen and (max-width: 767px) {
  .heading_one{
  margin-top:5rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  }
  .heading_two{
  margin-top:2rem;
  font-size: 1rem;
  font-weight: 100;
  text-align: center;
  }
.pad_top{
  padding-top:.5rem;
}
.bank_img{
  width:5rem;
  height:auto;
}
.tbody{
  padding:2rem!important;
  border-top:1px solid #000;
  font-size:0.8rem;
}
.head{
  margin-top:1rem!important;
  margin-bottom:1rem;
  padding-bottom:1rem;
  font-size: 0.8rem;
}
.btm_top{
  margin-left:1rem;
}
}
del {
 color:red !important;
}
.outerLoader{width: 100%; float:left; text-align: center;}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) 
{
  .mblslider{
    display: block i !important;
  }
  .webslider_1{
    display: none;
  }
    .mob{
      display: block  !important;
    }
    .web{
      display: none !important;
      position: absolute  !important;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .mblslider{
    display: block !important;
  }
  .webslider_1{
    display: none !important;
  }
.mob{
      display: block !important;
    }
    .web{
      display: none !important;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .mblslider{
    display: block !important;
  }
  .webslider_1{
    display: none !important;
  }
.mob{
      display: block !important;
    }
    .web{
      display: none !important;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
.mblslider{
    display: none !important;
  }
  .webslider_1{
    display: block !important;
  }
  .mob{
      display: none !important;
    }
    .web{
      display: flex !important;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .mblslider{
    display: none !important;
  }
  .webslider_1{
    display: block !important;
  }
  .mob{
      display: none !important;
    }
    .web{
      display: flex !important;
    }
}
.blink_me {
    animation: blinker 1s linear infinite;
    font-weight: 700;
    font-size: 2rem;
    float: right;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.table{
  font-size: 12px !important;
}
.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgb(23 20 20 / 3%);
    /* border-bottom: 1px solid rgba(0,0,0,.125); */
}
.card{
      background-color: #243046!important;
      color:white !important;
}
.card-footer {
    padding: .75rem 1.25rem;
    /* background-color: rgba(0,0,0,.03); */
    border-top: 1px solid white;
}
hr {
  border:none;
  border-top:1px dashed #0255AB;
  color:#fff;
  background-color:#fff;
  height:1px;
  width:100%;
}
</style>

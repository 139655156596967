<template>
<div class="container-fluid">
    <Header />
  <div class="row bg-blue content-padding pdt-70 relative d-flex">
    <ApplyProgress />
    <div class="col-md-8 col-sm-12 col-xs-12">
      <h3 class="color-white roboto-bold mgb-30 heading">
        Personal Detail Salaried
      </h3>
      <div class="row mgb-60">
        <form v-on:submit.prevent="submit">
          <div class="row">
            <!-- <div class="col-md-5 col-sm-6 col-xs-12 form-group">
              <label for="full-name" class="color-white">Full Name <span class="color-red">*</span></label>
              <input
                type="text"
                class="form-control"
                id="full-name"
                placeholder="Name as per PAN Card"
                name="name"
                v-model.trim="$v.name.$model"
                :class="{'is-invalid': validationStatus($v.name)}"
                 onKeyPress="if(this.value.length==40) return false;"
              />
              <div v-if="!$v.name.required&&flg" class="error-message color-red sub-heading">Full name is required.</div>
            </div> -->
            <div class="col-md-5 col-sm-6 col-xs-12 form-group">
              <label for="email" class="color-white">Email <span class="color-red">*</span></label>
              <input
                type="email"
                class="form-control"
                placeholder="Input Email ID"
                name="email"
                id="email"
                v-model.trim="$v.email.$model"
                :class="{'is-invalid': validationStatus($v.email)}"
              />
              <i
                class="fa fa-info-circle input-tooltip color-blue"
                delay="0"
                data-toggle="tooltip"
                data-placement="top"
                title="Ensure valid Email ID to receive timely communication for your application."
              ></i>
              <div v-if="!$v.email.required&&flg" class="error-message color-red sub-heading">Email is required.</div>
              <div v-if="!$v.email.email" class="error-message color-red sub-heading">Enter Valid Email</div>

            </div>
            <div class="col-md-5 col-sm-6 col-xs-12 form-group">
                <label for="date-of-birth" class="color-white">Date of Birth <span class="color-red">*</span></label>
                <datepicker id="dob" 
                  :bootstrap-styling="true" 
                  :open-date="new Date('1999-01-01')" 
                  v-model="date_of_birth" 
                  lang="en" 
                  type="month" 
                  placeholder="Please Select"
                  format="yyyy-MM-dd"
                  calendar-class="form-control"
                  v-model.trim="$v.date_of_birth.$model"
                  :disabled-dates="state.disabledDates"
                :class="{'is-invalid': validationStatus($v.date_of_birth)}"
                   >
                   </datepicker>

              <!-- <input
                type="date"
                class="form-control"
                id="date-of-birth"
                placeholder="Date of Birth"
                name="date_of_birth"
                v-model="date_of_birth"
                v-model.trim="$v.date_of_birth.$model"
                :class="{'is-invalid': validationStatus($v.date_of_birth)}"
              /> -->

              <div v-if="!$v.date_of_birth.required&&flg" class="error-message color-red sub-heading">Date of birth is required.</div>
            </div>
            <div class="col-md-5 col-sm-6 col-xs-12 form-group">
                  <label for="any_loans_running_emi_monthly" class="color-white">Current EMI<span class="color-red">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  id="any_loans_running_emi_monthly"
                  placeholder="Input Total EMI amount"
                  name="any_loans_running_emi_monthly"
                  v-model="any_loans_running_emi_monthly"
                  v-model.trim="$v.any_loans_running_emi_monthly.$model"
                  :class="{'is-invalid': validationStatus($v.any_loans_running_emi_monthly)}"
                  v-on:input="words"
                  onKeyPress="if(this.value.length==7) return false;"
                />
                <i
                  class="fa fa-info-circle input-tooltip color-blue"
                  delay="0"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="It Helps Calculate The Correct Eligible Loan Amount."
                ></i>
                <div v-if="any_loans_running_emi_monthly!=''" class="color-white number2words">{{words_str}}</div>
                <div v-if="!$v.any_loans_running_emi_monthly.required&&flg" class="error-message color-red sub-heading">EMI is required.</div>
              </div>

            <div class="col-md-5 col-sm-6 col-xs-12 form-group">
                 <label for="company-name" class="color-white">Company Name <span class="color-red">*</span></label>
                 <typeahead
                 :data = "autocomplete"
                 :minMatchingChars = "1"
                 :serializer="name => name.name"
                 class=""
                 id="current_company_name"
                 :placeholder="current_company_name?current_company_name:'Type Slowly and choose from dropdown'"
                 name="current_company_name"
                 v-model.trim="$v.current_company_name.$model"
                 :class="{'is-invalids': validationStatus($v.current_company_name)}"
                 @input="(name)=>{autocomplete_method()}"
                 @hit = "setLedgerID_1($event.id,$event.name)"
                 >
                 </typeahead>
                 <i
                   class="fa fa-info-circle input-tooltip color-blue"
                   delay="0"
                   data-toggle="tooltip"
                   data-placement="top"
                   title="Choose Your Current Company Name From Dropdown As It Plays A Key Role In Best Loan Offers. If You Don't Find A Valid Auto Suggestion, Type Your Company Name & Proceed Ahead."
                 ></i>
              <div v-if="!$v.current_company_name.required&&flg" class="error-message color-red sub-headings">Company name is required.</div>
            </div>

              <!-- We have set the fiexed value for Total Work Experience and Joining Date On LP1 Form 2 -->

            <!-- <div class="col-md-5 col-sm-6 col-xs-12 form-group">
                 <label for="work-experience" class="color-white">Total Work Experience <span class="color-red">*</span></label>

              <select name="workExperince"  v-model="total_work_experience" id="work-experience"
              class="form-control"
              placeholder="Dropdown"
              v-model.trim="$v.total_work_experience.$model"
              :class="{'is-invalid': validationStatus($v.total_work_experience)}"
              >
              <option value=''>Select An Option</option>
              <option value="0 - 1">0 - 1 Year</option>
              <option value="1 - 3">1 - 3 Years</option>
              <option value="3 - 5">3 - 5 Years</option>
              <option value="5 - 10">5 - 10 Years</option>
              <option value="Greater Than 10 Years">Greater Than 10 Years</option>
              </select>
              <div v-if="!$v.total_work_experience.required&&flg" class="error-message color-red sub-heading">Total Work Experience is required.</div>
            </div>
            <div class="col-md-2 col-sm-12 col-xs-12"></div>
            <div class="col-md-5 col-sm-6 col-xs-12 form-group">
                 <label for="joining_date_in_current_company" class="color-white">Joining In Current Company <span class="color-red">*</span></label>
              <select name="joining_date_in_current_company" v-model="joining_date_in_current_company" id="joining_date_in_current_company" class="form-control"
              v-model.trim="$v.joining_date_in_current_company.$model"
              :class="{'is-invalid': validationStatus($v.joining_date_in_current_company)}"
              >
              <option value=''>Select An Option</option>
                <option value="0 - 2 months">0 - 2 Months</option>
                <option value="2 - 6 months">2 - 6 Months</option>
                <option value="6 - 12 months">6 - 12 Months</option>
                <option value="1 - 2 yrs">1 - 2 yrs</option>
                <option value="Greater Than 2 yrs">Greater Than 2 yrs</option>
              </select>
              <div v-if="!$v.joining_date_in_current_company.required&&flg" class="error-message color-red sub-heading">Joining In Current Company is required.</div>
            </div> -->
            <div class="col-12 form-group mgt-15">
              <router-link to="/salaried/basic-detail">
                <button type="button" class="btn button-dark-blue form-button d-flex-inline justify-content-center align-items-center color-white bg-blue mgr-15">Previous</button>
              </router-link>
                <button type="submit" v-on:click="submit" class="btn salPLPSDCont form-button button-blue d-flex-inline justify-content-center align-items-center color-white bg-blue">Continue</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12">
     <ApplyFeature v-bind:list="list" />
    </div>
  </div>
  <OtherPages page="personal-loan"/>
  <Footer
  content="For instance, if Megha applies for a Personal loan of INR 3 Lacs and get the loan sanctioned at the rate of 11.99% with a tenure range of 5 years, in such a case her EMI would be INR 6,672 and complete repayment amount till the end of 5 years tenure would be INR 4,00,309 (i.e 6,672 EMI * 60 months), wherein the total interest amount is INR 1,00,309 along with principal repayment of INR 3,00,000. Few additional charges like those of minimal one-time processing fee ranging from 0.5% to 3% of loan amount, pre-payment penalty on an earlier foreclosure of loan ranging from 1% to 5% may also apply. T& C apply."
  />
</div>
</template>

<script>
import { required,numeric, minLength,maxLength,alpha,email,alphaNum,helpers } from 'vuelidate/lib/validators'
import ApplyProgress from '../../sub-components/ApplyProgress';
import ApplyFeature from '../../sub-components/ApplyFeature';
import ApplyReview from '../../sub-components/ApplyReview';
import Header from '../../sub-components/Header';
import Footer from '../../sub-components/Footer';
import OtherPages from '../../sub-components/OtherPages';
import typeahead from 'vue-bootstrap-typeahead';
import axios from '../../../axios-auth'
import Datepicker from 'vuejs-datepicker'

const alphaNumAndDotValidator = helpers.regex('alphaNumAndDot', /^[a-z\d.]*$/i);

import { ToWords } from 'to-words';
  const toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
    }
  });
export default {
  name: "PersonalDetailSalaried",
  data:function()
  {
      return{
        state : {
				disabledDates: {
					from: new Date(2000, 0, 1), // Disable all dates after specific date
				 }
			},
      words_str:'',
        showinvalid:false,
        flg:false,
          current_company_no:'',
        //  name:null,
          total_work_experience:'3 - 5',
          current_company_name:'',
          any_loans_running_emi_monthly:"",
          email:null,
          date_of_birth:'',
          joining_date_in_current_company:'1 - 2 yrs',
          autocomplete:[],
         list:[
          { 
            "title":"Personal Loan Starting at 9.99%",
            "new":true
          },
          {
            "title":"Best Offer to choose from 20+ Banks",
            "new":false
          },
          {
            "title":"No Bank Visits required",
            "new":true
          },
          {
            "title":"Cashback upto Rs 5,000",
            "new":true
          },
          {
            "title":"Instant Paperless Approvals",
            "new":true
          },
          {
            "title":"Free & Quick Service",
            "new":false
          },
          {
            "title":"Servicing Pan India Cities",
            "new":false
          },
          {
            "title":"Dedicated Loan Professional",
            "new":true
          },
          {
            "title":"Money in Account in 24 hrs",
            "new":true
          },
        ]
      }
  },
  mounted ()
  {
    if(localStorage.getItem("loan_amount_required")==null){
           this.$router.push('/salaried/basic-detail');
    }

  //  this.name=localStorage.getItem("name") ? localStorage.getItem("name") : null ;
    this.total_work_experience=localStorage.getItem("total_work_experience") ? localStorage.getItem("total_work_experience") : '';
     this.any_loans_running_emi_monthly=localStorage.getItem("any_loans_running_emi_monthly") ? localStorage.getItem("any_loans_running_emi_monthly") : "";
    this.email=localStorage.getItem("email") ? localStorage.getItem("email") : null;
    this.date_of_birth=localStorage.getItem("date_of_birth") ? localStorage.getItem("date_of_birth") : '1992-01-01';
    this.current_company_name=localStorage.getItem("current_company_name") ? localStorage.getItem("current_company_name") : null;
    this.joining_date_in_current_company = localStorage.getItem("joining_date_in_current_company") ? localStorage.getItem("joining_date_in_current_company") : '';
    this.current_company_no=localStorage.getItem("current_company_no") ? localStorage.getItem("current_company_no") : '';
  },
  components: {
    ApplyProgress,
    ApplyFeature,
    ApplyReview,
    Header,
    Footer,
    OtherPages,
    typeahead,
    Datepicker
  },
    validations: {
       // name: {required, minLength: minLength(2)},
        current_company_name: {required},
        date_of_birth:{required},
        any_loans_running_emi_monthly: {required,numeric,maxLength:maxLength(7)},
        email: {required,email}
        // joining_date_in_current_company: {required},
        // total_work_experience:{required},
    },
    methods: {
        words:function(){

            if (this.any_loans_running_emi_monthly.match("[0-9]+")==this.any_loans_running_emi_monthly){

            this.words_str=toWords.convert(this.any_loans_running_emi_monthly)}
            if(this.any_loans_running_emi_monthly=='' || this.any_loans_running_emi_monthly==null){
              console.log(this.any_loans_running_emi_monthly);
              this.words_str=''
            }
          },
      validateNuber:function(){
        if(this.panno!=""){
        if(this.panno.length < 10){
            this.showinvalid = true
        }else{
          this.showinvalid = false
        }
        }else{
           this.showinvalid = false
        }
      },
        validationStatus: function(validation) {
            return typeof validation != "undefined" ? validation.$error : false;
        },
        setLedgerID_1:function(id,name){
          this.current_company_no = id
        },
        submit: function() {
          
           this.flg=true;
           this.$v.$touch();
            if (this.$v.$pendding || this.$v.$error) {
              //this.total_work_experience = null
              //this.joining_date_in_current_company = null
                return 
              };

            let date = new Date(this.date_of_birth);

            let dd = date.getDate();
            let mm = date.getMonth() + 1;
            let yyyy = date.getFullYear();
            var dformat = yyyy+"-"+mm+"-"+dd;
           // console.log(dd + "-" + mm + "-" + yyyy);

            
            localStorage.setItem("any_loans_running_emi_monthly",this.any_loans_running_emi_monthly)
            localStorage.setItem("date_of_birth",dformat);
            localStorage.setItem("current_company_name",this.current_company_name);
            localStorage.setItem("current_company_no",this.current_company_no);
            localStorage.setItem("email",this.email);
           // localStorage.setItem("name",this.name);
            localStorage.setItem("total_work_experience",this.total_work_experience);
            localStorage.setItem("joining_date_in_current_company",this.joining_date_in_current_company);

            this.$router.push('/salaried/transaction-detail');
        },
      autocomplete_method:function() {
        this.flg=true;
        //console.log(this.current_company_name.length)
          if (this.current_company_name.length==3){
            axios.get("/company-filter/"+this.current_company_name)
            .then((res) =>this.autocomplete=res.data
          )
            .catch((err)=>console.log(err))
          }
          else{
            for (var i in this.autocomplete){

              if (this.autocomplete[i].name == this.current_company_name){
                this.current_company_no = this.autocomplete[i].id;
                // console.log(this.autocomplete[i]);
              }
            }
          }

      },
      current_company_no_setter:function(i){
        console.log(i,i.id);
        this.current_company_no=i;
      },


      },

};
</script>

<template>
  

  <div class="row content-padding pd-30-0 bg-white">
    <!-- The Modal -->
<div class="modal" id="eligibilty">
  <div class="modal-dialog modal-lg" style="font-size:16px">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Eligibility of a Personal Loan</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
       <div class="container-fluid">
    <div class="row bg-blue px-10">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-sm-8 col-8 d-flex justify-content-start align-items-center">
            <h3 class="heading color-white roboto-bold banner-heading">
              Eligibility of a Personal Loan
            </h3>
          </div>
          <div class="col-md-4 col-sm-4 col-4 banner-image-section d-flex justify-content-end">
            <!-- <img src="../../assets/img/eligibility-banner.png" /> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="container">
        <h3 class="heading mgt-30">
          There are different eligibility criteria for Salaried loan product from various banks. 
          Few common eligibility criteria are as below:-
        </h3>
          <div class="mgb-30 col-md-6 list-container flex-column d-flex justify-content-center pt-5">
                    <ul class="list feature-list">
                        <li class="list-heading">Age limit:21 to 58 years
                        </li>
                     <li class="list-heading">Employment: Salaried, Employed with an MNCs, public or private company</li>
                     <li class="list-heading">Minimum Salary: Rs 30000 per month</li>
                     <li class="list-heading">Credit Score above 650</li>
                     <li class="list-heading">APR Ranges from 9.99% to 19% p.a</li>
                     <li class="list-heading">Repayment Tenure ranges from 2 yrs to 6 yrs</li>
                    </ul>
                   
                </div>
        <!-- <div class="row mgt-30 mgb-30">
          <table class="table table-bordered">
            <thead>
              <tr class="bg-blue color-white">
                <th>Categories</th>
                <th>Eligibility</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Age</td>
                <td>23 to 58 years</td>
              </tr>
              <tr>
                <td>Employment</td>
                <td>
                  Salaried, Employed with an MNCs, public or private company
                </td>
              </tr>
              <tr>
                <td>APR Ranges</td>
                <td>from 10.40% to 19% p.a</td>
              </tr>
              <tr>
                <td>Minimum Salary</td>
                <td>RS 30000 per month</td>
              </tr>
              <tr>
                <td>Repayment Tenure ranges</td>
                <td>from 2 yrs to 7yrs</td>
              </tr>
              <tr>
                <td>Credit Score</td>
                <td>Above 650</td>
              </tr>
            </tbody>
          </table>
        </div> -->
      </div>
    </div>
    
  </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>


<div class="modal" id="feature">
  <div class="modal-dialog modal-lg" style="font-size:16px">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Features and Benefits</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
       <div class="container-fluid">
    <div class="row bg-blue px-10">
      <div class="container">
          <div class="row">
        <div class="col-md-8 col-sm-8 col-8 d-flex justify-content-start align-items-center">
          <h2 class="heading color-white roboto-bold banner-heading">Some Key Benefits of Personal Loans</h2>
        </div>
        <div class="col-md-4 col-sm-4 col-4 banner-image-section d-flex justify-content-end">
          <!-- <img src="../../assets/img/benefits-banner.png" /> -->
        </div>
          </div>
      </div>
    </div>
    <div class="row bg-white">
        <div class="container">
            <div class="row">
                <div class="col-md-12 mgt-15">
                <h3 class="heading">Get to Choose the Best Lender for Your Personal Loan Needs</h3>
                <p class="sub-heading">
                 A personal loan is one of the best remedies of modern life's financial concern. 
                 Today almost everyone came across cash crunch situation and financial requirement that need fund at the moment, 
                 while one can gradually pay them over the period of time, 
                 these requirements differs from education for self or family , 
                 medical requirements, home renovation , travel desires or full fill a long wishlist of a gadget and severalother requirements.
                </p>
                 <p class="sub-heading">
                 While there are several ways to get access to funds, asking friends and family may be a risky proportion. 
                 Thus the best bet is to opt for personal loan from financial institutions without any collateral.
                </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mgt-10">
                    <h3 class="heading">Features and Benefits</h3>
                </div>
               
                <div class="mgb-30 col-md-6 list-container flex-column d-flex justify-content-center">
                    <ul class="list feature-list">
                        <li class="list-heading">Loan Upto 50 Lacs
                        </li>
                     <li class="list-heading">Digital Documentation</li>
                     <li class="list-heading">Best Offer to choose from 20+ Banks & NBFCs</li>
                     <li class="list-heading">LoansJagat Service is Absolutely Free of Cost for their Customers</li>
                     <li class="list-heading">Best Deal Guranteed</li>
                     <li class="list-heading">No Collateral or guarantor needed</li>
                     <li class="list-heading">Dedicated Loan Professional for profile based assessment.</li>
                     <li class="list-heading">Highest Approval Ratio</li>
                     <li class="list-heading">Maximum Loan Eligibility Offers</li>
                     <li class="list-heading">Cashback upto Rs. 5,000 On disbursal of your loan</li>
                    </ul>
                   
                </div>
            </div>
        </div>
    </div>
   
  </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>

<div class="modal" id="document_req">
  <div class="modal-dialog modal-lg" style="font-size:16px">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title"> Document Requirement For Personal Loan</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
       <div class="container-fluid">
    <div class="row bg-blue px-10">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-sm-8 col-8 d-flex justify-content-start align-items-center">
            <h3 class="heading color-white roboto-bold banner-heading">
              Document Requirement For Personal Loan
            </h3>
          </div>
          <div class="col-md-4 col-sm-4 col-4 banner-image-section d-flex justify-content-end">
            <!-- <img src="../../assets/img/document-banner.png" /> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row bg-white">
      <div class="container">
        <div class="row">
          <div class="col-md-12 mgt-15">
            <h3 class="heading">Personal Loan - Document Required</h3>
            <p class="sub-heading">
             The lender seek following documents from the Personal loan applicants. 
             These documents can be submitted either as hard copy by physically 
             visiting the bank or by simple emailing the documents or uploading the scanned copy on the digital platform/website .
            </p>
          </div>
        </div>
        <div class="row">
         
         
          <div
            class="col-md-6 list-container flex-column d-flex justify-content-center pt-5"
          >
            <ul class="list feature-list">
              <li class="list-heading">Aadhar Card</li>
              <li class="list-heading">PAN Card</li>
              <li class="list-heading">Current Address Proof</li>
              <li class="list-heading">Last Salary Slips</li>
              <li class="list-heading">Latest Bank Statement</li>
            </ul>
          
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <Footer />
    </div> -->
  </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>
<div class=" other-page col-md-2 col-sm-4 col-xl-4 d-flex flex-column align-items-center"
>
    <a href="#"  class="pagelink d-flex flex-column justify-content-center align-items-center" data-toggle="modal" data-target="#feature">
      <!-- <router-link
        class="pagelink d-flex flex-column justify-content-center align-items-center"
        v-bind:to="`/${page}/benefits`"
        
      > -->
        <img src="../../assets/img/benefits@2x.png" alt="Benifits" />
        <p class="mgt-15 color-black sub-heading">Features and Benefits</p>
      <!-- </router-link> -->
    </a>
    </div>
    <div
      class=" other-page col-md-8 col-sm-4 col-xl-4 d-flex flex-column align-items-center"
    >
        <a href="#"  class="pagelink d-flex flex-column justify-content-center align-items-center" data-toggle="modal" data-target="#eligibilty">

      <!-- <router-link
        class="pagelink d-flex flex-column justify-content-center align-items-center"
        v-bind:to="`/${page}/eligibility`"
      > -->
        <img src="../../assets/img/eligibility.png" alt="Eligibility" />
        <p class="mgt-15 color-black sub-heading">Eligibility</p>
        </a>
      <!-- </router-link> -->
    </div>
    <div
      class="other-page col-md-2 col-sm-4 col-xl-4 d-flex flex-column align-items-center"
    >
        <a href="#"  class="pagelink d-flex flex-column justify-content-center align-items-center" data-toggle="modal" data-target="#document_req">

      <!-- <router-link
        class="pagelink d-flex flex-column justify-content-center align-items-center"
        v-bind:to="`/${page}/document-requirements`"
      > -->
        <img
          src="../../assets/img/document-required.png"
          alt="Document Required"
        />
        <p class="mgt-15 color-black sub-heading">Document Required</p>
        </a>
      <!-- </router-link> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "OtherPages",
  components: {},
  props: ["page"],
};
</script>

<style scoped>
.col-md-2 img,
.col-md-8 img {
  background-color: #fff;
  padding: 10px;
  width: 70px;
  box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 0.4);
}
</style>

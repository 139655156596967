<template>
  <div class="row bg-black pd-15-15">
    <div class="col-md-12">
        <p class="color-white roboto sub-heading">
        For instance, if Megha applies for a Personal loan of INR 3 Lacs and get the loan sanctioned at the rate of 11.99% with a tenure range of 5 years, in such a case her EMI would be INR 6,672 and complete repayment amount till the end of 5 years tenure would be INR 4,00,309 (i.e 6,672 EMI * 60 months), wherein the total interest amount is INR 1,00,309 along with principal repayment of INR 3,00,000. Few additional charges like those of minimal one-time processing fee of 2% (i.e Rs 6000), stamp duty charges of Rs 500 will also apply. T& C apply.
        
       </p>
        
        <p class="color-white roboto sub-heading pt-3">
Annual Percentage Rate offered to customer Min APR – 9.99 % . Max APR – 17.99 %. Repayment Tenure :Min 2 years , Max 7 years .

        </p>
        <p class="color-white roboto pt-3" style="font-size:14px">
         Disclaimer:  LoansJagat is a loan aggregator and is authorized to provide services on behalf of its partners with best guaranteed deals 
        </p>
         <div class="text-center" style="background-color:#999999">
      <p class="color-black pt-3" align="center" style="text-align:center;font-size:12px">LoansJagat (AF Financial Technology Pvt Ltd)
</p>
<p style="text-align:center;font-size:10px"  class="color-black">A-62 , Sector 2 , Noida (UP) 201301</p>
    </div>
    </div>
   
  </div>
</template>

<script>
export default {
  name: 'Footer',
  components: {
  },
  props:['content']
}
</script>
<style scoped>
p{
  line-height: 25px;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Axios from 'axios'
import VueCloneya from "vue-cloneya";
import GoogleSignInButton from 'vue-google-signin-button-directive'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueMeta from 'vue-meta'
import './assets/css/main.css';
import VueAnalytics from 'vue-analytics'
import VueGtag from "vue-gtag";

//https://stackoverflow.com/questions/56322820/how-to-use-gtag-js-with-nuxt-js

// import VueGtagCoverstion from 'vue-gtag-conversion';


import Vuelidate from 'vuelidate';

const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};

Vue.use(VueAnalytics, {
  id: 'UA-200709827-1',
  router 
})

// Vue.use(VueGtagCoverstion, {
//   id: 'AW-756284985', // Your Adwords ID
//   enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: process.env.NODE_ENV !== 'production' (optional)
//   debug: true, // Whether or not display console log debugs (optional)
// });

// Vue.use(VueGtag, {
//   config: { id: "AW-756284985" },
//   includes: [
//     { 
//       id: 'AW-4701282621',
//       params: {
//         send_page_view: false
//       }
//     }
//   ]
// });


// Vue.use(VueGtag, {
//   pageTrackerExcludedRoutes: [
//     'route_path_value', 
//     'route_name_value'
//   ],
//   config: { 
//     id: "AW-756284985",
//   },
//   includes: [
//     { 
//       id: 'AW-4701282621',
//       params: {
//         send_page_view: false
//       }
//     },
//     { 
     
//       id: 'AW-309981734',
      
//       params: {
//         send_page_view: false
//       }
//     }
//   ]
// }, router);

Vue.use(VueGtag, {
  config: { 
    id: "AW-756284985",
    params: {
      send_page_view: false
    }
  },
  includes: [
    { 
      id: 'AW-4701282621',
      params: {
        send_page_view: false
      }
    },
    { 
      id: 'AW-309981734',
      params: {
        send_page_view: false
      }
    }
  ]
});

Vue.use(Vuelidate);

Vue.use(VueMeta,{
  keyName:'head'
})

Vue.use(VueSweetalert2, options);


Vue.use(VueCloneya)
Vue.config.devtools = true
Vue.config.performance = true

window.bus = new Vue();

Vue.config.productionTip = false

Vue.prototype.$http = Axios;

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    // Object.keys(tagDef).forEach(key => {
    //   tag.setAttribute(key, tagDef[key]);
    // });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    //tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

new Vue({
  beforeMount() {
    window.addEventListener("load", this.onLoad);
    window.addEventListener("beforeunload", this.onUnload);
  },
  beforeDestroy() {
    window.removeEventListener("load", this.onLoad);
    window.removeEventListener("beforeunload", this.onUnload);
  },
  methods: {
    onLoad(event) {
    
      //alert("onload")
     // window.localStorage.setItem("isMySessionActive", true);
    },
    onUnload(event) {

      localStorage.removeItem("loan_amount_required")
         localStorage.removeItem("mobile_number")
         localStorage.removeItem("net_salary_all_deductions")
         localStorage.removeItem("any_loans_running_emi_monthly")
         localStorage.removeItem("civil_score")
         localStorage.removeItem("current_city_other")
         localStorage.removeItem("current_company_no")
         localStorage.removeItem("date_of_birth")
         localStorage.removeItem("email")
         localStorage.removeItem("joining_date_in_current_company")
         localStorage.removeItem("mode_of_salary")
         localStorage.removeItem("name")
         localStorage.removeItem("current_company_name")
         localStorage.removeItem("panno")
         localStorage.removeItem("salary_account_name")
         
         localStorage.removeItem("ownership")
        localStorage.removeItem('source')



      localStorage.removeItem("response_id");
      //alert("unload")
     // window.localStorage.setItem("isMySessionActive", false);
    }
  },
  router,
  GoogleSignInButton,
  render: h => h(App),
}).$mount('#app')

<template>
  <div class="container-fluid">
      <Header />
  <div class="row  contai">
    
    <div class=" w-100">
      <div class="d-flex flex-row justify-content-center align-items-center p-4">
        <img class='tick' alt="img" src="../../assets/img/white-check-mark.png">
      </div>
      
      <div class="h1">
          Thank You
      </div>
      <div class=" p">
        Congratulations! You have just taken the right step towards creating Single EMI !
      </div>
      <div class="d-flex flex-row justify-content-center">
        <div class="box shadow d-flex flex-row text" style="height:15rem; width:80% !important; text-align:justify">
            <div v-if="condition" style="padding:20px 10px 10px 10px;">
              Thank You {{full_name}}, we have received your application for Debt Consolidation! Our Loan Professionals are on it and will get back to you within 24 hours with the best proposal for creating Single EMI for you !
            <br>
            <br>
            
            Stay Safe! Stay Financially Fit!<br>
            Thanks,<br>
            LoansJagat
            
            </div>
            <div v-else style="padding:20px 10px 10px 10px;">
            We’re sorry {{full_name}}, your financial profile is not meeting the internal parameter to proceed further with the Debt Consolidation(Single EMI) application😐. We look forward to serving you in the future after 3-6 months. Thank you for applying.
               <br>
            <br>
            Stay Safe! Stay Financially Fit!<br>
            Thanks,<br>
            LoansJagat
            </div>

        </div>
      </div>
      <div class="">
      </div>
    </div>
  </div>
  </div>
</template>

<script>

import Header from '../sub-components/Header';

export default {
  name: "AcknowledgementThankyouPage ",
  data: function()
  {
      return {
       page:'self-employed',
       text:'',
       condition:true,
       full_name:''
      }
  },
  components: {
    Header,
  },
  created(){
    this.full_name = localStorage.getItem('name')
    if(localStorage.getItem('net_salary_all_deductions') < 35000 && localStorage.getItem('emi_delay_last_6_months')=='yes'){
        this.condition = false
    }
    console.log(this.$route.query);
    this.text=this.$route.query.no
  },
  mounted(){
    localStorage.clear();
  }
};
</script>
<style scoped>
.h1{
    margin-bottom: 10px;
    color:#fff;
    font-size: 4rem;
    font-weight: 600;
    padding-bottom:1.5rem;
}
.p{
  color:#fff;
  font-size: 1.7rem;
  /* font-weight: 500; */
}
.contai{
  min-height:calc(100vh - 59px);
  background-color:#0255AB;
  text-align: center;
  /* position: fixed; */
}
.tick{
  width:10rem;
}
.box{
  margin-top:3.5rem;
  background-color:#fff;
  width:65%;
  height:13rem;
  color:#0255AB;
  border-radius: 5px;
}
.text{
  font-size: 2rem;
}
@media screen and (max-width: 767px) {
  .text{
    font-size: 2rem;
  }
  .tick{
    width:7rem;
  }
  .h1{
      margin-bottom: 10px;
      color:#fff;
      font-size: 3rem;
      font-weight: 600;
      padding-bottom:1.5rem;
  }
  .p{
    color:#fff;
    font-size: 1.3rem;
    /* font-weight: 500; */
  }

}
@media screen and (max-width: 480px) {
  .text{
    font-size: 1.2rem;
  }
  .tick{
    width:5rem;
  }

}
</style>

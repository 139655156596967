<template>
  <div class="bg-blue">
    <div class="overflow-hidden sticky-top">
      <Header />
    </div>
    <div class="container-fluid min-height-80vh">
      <div class="row row-custom-css-1 d-md-none">
        <div class="col-lg-7"></div>

        <div class="col-lg-5 progress-responsive bg-blue">
          <!-- progress -->
          <div class="progress-container mb-5 mt-5">
            <ul class="list list-inline progress-list">
              <li class="list-inline-item active">
                <i class="fa fa-check number-icon"></i>
                <p class="color-white">Basic Details</p>
              </li>
              <li class="list-inline-item">
                <i class="fa fa-check number-icon"></i>
                <p class="color-white">Personal Details</p>
              </li>
              <li class="list-inline-item">
                <i class="fa fa-check number-icon"></i>
                <p class="color-white">
                  Financial Details
                </p>
              </li>
              <li class="list-inline-item">
                <i class="fa fa-check number-icon"></i>
                <p class="color-white">Get Offers</p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row flex-column-reverse flex-md-row">
        <div class="col-lg-7 col-md-5">
          <!-- image -->
          <div class="d-md-block mb-5">
            <img src="/img/women-loan.jpg" class="w-100" alt="" />
          </div>

          <!-- features List -->
          <div class="d-md-flex d-none">
            <div class="mr-md-5">
              <ul class="features list">
                <li class="d-flex align-items-center list-heading">
                  <div class="color-white">
                    Personal Loan Starting at 10.40%
                  </div>
                </li>

                <li class="d-flex align-items-center list-heading">
                  <div class="color-white">10.4% interest rate</div>
                  <sup class="blink">New</sup>
                </li>

                <li class="d-flex align-items-center list-heading">
                  <div class="color-white">Money in Account in 24 hrs</div>
                </li>

                <li class="d-flex align-items-center list-heading">
                  <div class="color-white">Cashback upto Rs 5,000</div>
                  <sup class="blink">New</sup>
                </li>
              </ul>
            </div>

            <div>
              <ul class="features list">
                <li class="d-flex align-items-center list-heading">
                  <div class="color-white">Instant Paperless Approvals</div>
                  <sup class="blink">New</sup>
                </li>

                <li class="d-flex align-items-center list-heading">
                  <div class="color-white">Free and Quick Service</div>
                </li>

                <li class="d-flex align-items-center list-heading">
                  <div class="color-white">Easy Debt Consolidation</div>
                  <sup class="blink">New</sup>
                </li>

                <li class="d-flex align-items-center list-heading">
                  <div class="color-white">Servicing Pan India Cities</div>
                </li>
              </ul>
            </div>
          </div>

          <hr class="bg-white mb-5" />

          <!-- Links -->
          <div class="d-flex flex-lg-row flex-column justify-content-between">
            <div
              data-toggle="modal"
              data-target="#Features"
              class="cursor-pointer mb-5"
            >
              <div class="media align-items-center">
                <div class="bg-white mr-3 rounded-circle p-3">
                  <img
                    src="/img/benefits@2x.png"
                    class="icon-size-small"
                    alt="..."
                  />
                </div>
                <div class="media-body">
                  <h5 class="mt-0 color-white sub-heading">
                    Features & Benefits
                  </h5>
                </div>
              </div>
            </div>

            <div
              data-toggle="modal"
              data-target="#Features"
              class="cursor-pointer mb-5"
            >
              <div class="media align-items-center">
                <div class="bg-white mr-3 rounded-circle p-3">
                  <img
                    src="/img/eligibility.png"
                    class="icon-size-small"
                    alt="..."
                  />
                </div>
                <div class="media-body">
                  <h5 class="mt-0 color-white sub-heading">
                    Eligibility
                  </h5>
                </div>
              </div>
            </div>

            <div
              data-toggle="modal"
              data-target="#Features"
              class="cursor-pointer mb-5"
            >
              <div class="media align-items-center">
                <div class="bg-white mr-3 rounded-circle p-3">
                  <img
                    src="/img/document-required.png"
                    class="icon-size-small"
                    alt="..."
                  />
                </div>
                <div class="media-body">
                  <h5 class="mt-0 color-white sub-heading">
                    Documents Required
                  </h5>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              id="Features"
              tabindex="-1"
              role="dialog"
              aria-labelledby="FeaturesLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="heading mb-3">
                      Get to Choose the Best Lender for Your Business Loan Needs
                    </div>
                    <div class="sub-heading">
                      A business loan is one of the best remedies of modern
                      life's financial concern. Today almost everyone came
                      across cash crunch situation and financial requirement
                      that need fund at the moment, while one can gradually pay
                      them over the period of time, these requirement from
                      educationfor self or family. medical requirements, need to
                      travel or fullfill a long wishlist of a gadget and several
                      other requirements. While there are several ways to get
                      access to funds, asking friends and family may be a risky
                      proportion. Thus the best bet is to opt for business loan
                      from financial institutions
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-5 col-md-7 mb-5">
          <!-- progress -->
          <div class="progress-container mb-5 mt-5 d-none d-md-block">
            <ul class="list list-inline progress-list">
              <li class="list-inline-item active">
                <i class="fa fa-check number-icon"></i>
                <p class="color-white">Basic Details</p>
              </li>
              <li class="list-inline-item">
                <i class="fa fa-check number-icon"></i>
                <p class="color-white">Personal Details</p>
              </li>
              <li class="list-inline-item">
                <i class="fa fa-check number-icon"></i>
                <p class="color-white">
                  Financial Details
                </p>
              </li>
              <li class="list-inline-item">
                <i class="fa fa-check number-icon"></i>
                <p class="color-white">Get Offers</p>
              </li>
            </ul>
          </div>

          <!-- heading -->
          <div class="mgb-30">
            <h1 class="color-white">
              <span class="heading">Compare and Apply For</span> <br />
              <span class="big-heading">Personal Loan in 2 mins</span>
            </h1>
          </div>

          <!-- List -->
          <div class="d-md-none mb-5">
            <ul class="features list color-white">
              <li class="d-flex align-items-center list-heading">
                Personal Loan Starting 10.40%
              </li>
              <li class="d-flex align-items-center list-heading">
                Instant paperless approvals
                <sup class="blink">New</sup>
              </li>
              <li class="d-flex align-items-center list-heading">
                Free quick service
              </li>
              <li class="d-flex align-items-center list-heading">
                10.4% intrest rate
                <sup class="blink">New</sup>
              </li>
              <li class="d-flex align-items-center list-heading">
                Money in Account within 24 hrs
              </li>
              <li class="d-flex align-items-center list-heading">
                Cashback upto Rs 5,000
                <sup class="blink">New</sup>
              </li>
              <li class="d-flex align-items-center list-heading">
                Servicing Pan India Cities
              </li>
              <li class="d-flex align-items-center list-heading">
                Easy debt consolidation
                <sup class="blink">New</sup>
              </li>
            </ul>
          </div>

          <!-- Ratings -->
          <div class="mb-5">
            <div class="mb-2 ratings">
              <span class="star sub-heading fa fa-star checked"></span>
              <span class="star sub-heading fa fa-star checked"></span>
              <span class="star sub-heading fa fa-star checked"></span>
              <span class="star sub-heading fa fa-star checked"></span>
              <span class="star sub-heading fa fa-star-half-o"></span>
            </div>

            <div>
              <a href="" class="font-small color-white"
                >4.6/5 Rating 1488 Reviews</a
              >
            </div>
          </div>

          <!-- Form -->
          <form>
            <div class="form-row">
              <div class="form-group col-lg-6 mb-5">
                <label class="color-white">Loan Amount Required</label>
                <input
                  type="number"
                  class="form-control form-control-lg"
                  placeholder="eg 5,00,000"
                />
              </div>
              <div class="form-group col-lg-6 mb-5">
                <label class="color-white">Net Monthly Salary</label>
                <input
                  type="number"
                  class="form-control form-control-lg"
                  placeholder="e.g 40,000"
                />
                <i
                  class="fa fa-info-circle input-tooltip color-white"
                  delay="0"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="You can apply for minimum loan of One lakh"
                ></i>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-lg-6 mb-5">
                <label class="color-white">Your City</label>
                <select class="form-control form-control-lg">
                  <option selected>Choose from Dropdown</option>
                </select>
              </div>
              <div class="form-group col-lg-6 mb-5">
                <label class="color-white">Mobile Number</label>
                <input
                  type="number"
                  class="form-control form-control-lg"
                  placeholder="Enter your 10 Digit Mobile Number"
                />
                <i
                  class="fa fa-info-circle input-tooltip color-white"
                  delay="0"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="You can apply for minimum loan of One lakh"
                ></i>
              </div>
            </div>

            <div class="d-flex">
              <div class="col-lg-3 col-6 p-lg-0 mr-lg-5">
                <button class="btn btn-outline-secondary btn-lg w-100">
                  BACK
                </button>
              </div>

              <div class="col-lg-3 col-6 p-lg-0">
                <button class="btn btn-color-blue btn-lg w-100">
                  NEXT
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Footer Text -->
    <div class="overflow-hidden">
      <Footer
        content="For instance, if Megha applies for a Personal loan of INR 3 Lacs and get the loan sanctioned at the rate of 11.99% with a tenure range of 5 years, in such a case her EMI would be INR 6,672 and complete repayment amount till the end of 5 years tenure would be INR 4,00,309 (i.e 6,672 EMI * 60 months), wherein the total interest amount is INR 1,00,309 along with principal repayment of INR 3,00,000. Few additional charges like those of minimal one-time processing fee ranging from 0.5% to 3% of loan amount, pre-payment penalty on an earlier foreclosure of loan ranging from 1% to 5% may also apply. T& C apply."
      />
    </div>
  </div>
</template>

<script>
import Footer from "../sub-components/Footer";
import Header from "../sub-components/Header";
export default {
  components: {
    Footer,
    Header,
  },
};
</script>

<style scoped>
.font-small {
  font-size: 10px;
}

.min-height-80vh {
  min-height: 80vh;
}

.overflow-hidden {
  overflow: hidden;
}

.features.list li:not(:last-child) {
  margin-bottom: 15px;
}
.features.list li::before {
  content: "";
  display: inline-block;
  height: 20px;
  width: 20px;
  background-image: url("../../assets/img/checked.svg");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}
sup {
  color: red;
  margin-left: 5px;
}

.ratings .star {
  color: gold;
}

.bg-blue {
  background: #142a4c;
}

.icon-size-small {
  width: 25px;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-color-blue {
  background: #426dfa;
  color: white;
}

/* Page progress */

.progress-list {
  list-style: none;
}
.progress-list > li {
  width: 100px;
  position: relative;
}

.progress-list > li.active:not(:last-child):after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #4883ff !important;
  position: absolute;
  top: 25%;
  left: 30px;
}

.progress-list > li:not(:last-child):after {
  content: "";
  width: 79px;
  height: 2px;
  background-color: #4883ff !important;
  position: absolute;
  top: 25%;
  left: 30px;
}

.progress-list > li.active > svg,
.progress-list > li.active > .number-icon {
  background-color: #4883ff;
  color: white;
}

.progress-list > li > i {
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 5px;
  border: 1px solid;
}
.progress-list > li svg path {
  fill: blue;
}
p {
  font-size: 10px;
}
.number-icon {
  display: inline-flex;
  background-color: rgba(255, 255, 255, 0.3);
  height: 18px;
  width: 18px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  transform: translateY(-4px);
  color: #c5c5c5;
}
/* ///////////// */

@media only screen and (max-width: 1200px) {
  ul li {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1024px) {
  .progress-list > li {
    width: 25%;
    text-align: center;
  }

  .progress-list {
    text-align: center;
  }

  .progress-list > li.active:not(:last-child):after {
    width: 100% !important;
    left: 60px !important;
  }

  .progress-list > li:not(:last-child):after {
    width: 100% !important;
    left: 60px !important;
  }
}

@media only screen and (max-width: 700px) {
  .row-custom-css-1 {
    margin-bottom: 130px;
  }
  .progress-responsive {
    position: fixed;
    /* background: white; */
    z-index: 100;
  }
}
</style>

<template>
  <div class="row bg-black pd-15-15">
    <div class="col-md-12">
         <h4 class="color-white heading">What is Debt Consolidation?</h4>
               
        <p class="color-white roboto sub-heading">
        Debt Consolidation is an effective way to combine all Personal Loan EMIs and Credit Cards bills ( if any) into a one structured EMI at lowest rate of interest. Instead of managing multiple EMIs and credit card bills, consolidate all debts in a single personal loan, reducing the chances of missing payments because of multiple payment dates and variations in amounts.
        </p>
         <p class="color-white roboto sub-heading">
            The multiple credit card bills which never seem to come down and the personal loan EMI that makes salary disappear can easily be managed via Debt Consolidation.            </p>
    </div>
    <div class="col-md-12 pt-3">
        <p class="color-white roboto sub-heading">For instance, if Megha has following existing Debts running:-
            
</p>


         <ul class="list">
              <li class="color-white roboto sub-heading pt-2">a)&nbsp; 3 Personal Loans ( Total Outstanding Amount = 7 lacs)</li>
              <li class="color-white roboto sub-heading pt-4">b)&nbsp; 4 credit cards ( Total Outstanding Amount = 3 lacs)</li>
              
            </ul>
            <p class="color-white roboto sub-heading pt-4">She can easily apply for a Debt Consolidation loan of INR 10 Lacs ( 7 lacs + 3 lacs ) and get single EMI for all debts from one Bank/NBFC and hence saving herself from high credit card charges and higher rate EMI . Suppose she get the loan sanctioned at the rate of 11.5% with a tenure range of 5 years, in such a case her EMI would be INR 21,993 and this complete amount till the end of 5 years tenure would be INR 13,19,554(i.e 21,993 EMI * 60 months), which is a total interest amount of INR 3,19,554 along with principal repayment of INR 10,00,000. Some additional charges like those of minimal one-time processing fee ranging from 0.5% to 3% of loan amount, charges on change of the mode of payment (Rs. 500- 1000) or pre-payment penalty on an earlier foreclosure of loan ranging from 1% to 5% may also apply.
</p>
<p class="color-white roboto sub-heading pt-3">The repayment period for Debt Consolidation  ranges between 1 to 7 years which is comparatively shorter than other types of loans. Depending on the financial records and credit score of the applicant, the Annual Percentage Rate (APR) of a personal loan can vary from 10.50% to 17%.   
</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  components: {
  },
  props:['content']
}
</script>
<style scoped>
p{
  line-height: 25px;
}
</style>
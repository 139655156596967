  <template>
    <div class="container-fluid">
        <Header />
    <div class="row bg-blue content-padding pdt-70 relative d-flex">
      <ApplyProgress />
      <div class="col-md-8 col-sm-12 col-xs-12">
        <h3 class="color-white roboto-bold mgb-30 heading">
          Financial Details
        </h3>
        <div class="row mgb-60">
          <form v-on:submit.prevent="submit">
            <div class="row">
              <div class="col-md-5 col-sm-6 col-xs-12 form-group">
                <label for="residential-ownership" class="color-white">Residential Ownership<span class="color-red">*</span></label>
                <select name="ownership" v-model="ownership"
                class="form-control"
                id="residential-ownership"
                v-model.trim="$v.ownership.$model"
                :class="{'is-invalid': validationStatus($v.ownership)}"
                >
                  <option value="">Select An Option</option>
                    <option value="Owned by Self/Spouse">Owned by Self/Spouse</option>
                    <option value="Owned By Parents">Owned By Parents</option>
                    <option value="Owned By Parents">Owned by Siblings</option>
                    <option value="Rented with Family">Rented with Family</option>
                    <option value="Rented with Friends">Rented with Friends</option>
                    <option value="Rented Staying Alone">Rented Staying Alone</option>
                    <option value="Hostel/PG">Hostel/PG</option>
                    <option value="Hostel/PG">Company/Govt Provided</option>
                  </select>
                <div v-if="!$v.ownership.required&&flg" class="error-message color-red sub-heading">Residential ownership is required.</div>
              </div>
              <div class="col-md-5 col-sm-6 col-xs-12 form-group">
                <label for="salary-type" class="color-white">Salary Type<span class="color-red">*</span></label>
                <select name="salary-type" v-model="mode_of_salary" class="form-control" id="salary-type"
                v-model.trim="$v.mode_of_salary.$model"
                :class="{'is-invalid': validationStatus($v.mode_of_salary)}"
                @change='reset_salary_account_name'
                >
                  <option value="">Select An Option</option>
                <option value="Cash">Cash</option>
                <option value="Cheque">Cheque</option>
                <option value="Direct Bank">Direct Bank</option>
                </select>
                <div v-if="!$v.mode_of_salary.required&&flg" class="error-message color-red sub-heading">Salary Type is required.</div>
              </div>
              <div class="col-md-2 col-sm-12 col-xs-12 form-group"></div>
              
              <div class="col-md-5 col-sm-6 col-xs-12 form-group">
                  <label for="credit-score" class="color-white">Credit Score<span class="color-red">*</span></label>
                <select name="civil_score" v-model="civil_score" id="credit-score" class="form-control"
                v-model.trim="$v.civil_score.$model"
                :class="{'is-invalid': validationStatus($v.civil_score)}"
                >
                  <option value="">Select An Option</option>
                <option value="Below 650">Below 650</option>
                <option value="Above 650">Above 650</option>
                <option value="Don't know">Don't Know</option>
                </select>
                  <div v-if="!$v.civil_score.required&&flg" class="error-message color-red sub-heading">Credit Score is required.</div>
              </div>
              <div v-if="mode_of_salary=='Direct Bank'" class="col-md-5 col-sm-6 col-xs-12 form-group">
                <label for="salary_account_name" class="color-white">Salary Bank<span class="color-red">*</span></label>
                  <select  class="form-control " id="salary_account_name" v-model="salary_account_name"
                  :class="{'is-invalid': this.mode_of_salary=='Direct Bank' && this.salary_account_name==''}">
                    <option value="">Select Bank</option>
                    <option v-for="bank in banks" :key="bank.id" :value="bank.id">{{bank.bank_name}}</option>
                  </select>
                <div v-if="this.mode_of_salary=='Direct Bank' && this.salary_account_name=='' &&flg" class="error-message color-red sub-heading">Salary Bank is required</div>
              </div>
              <div class="col-md-5 col-sm-6 col-xs-12 form-group">
              <label for="pan" maxlength="10" class="color-white">PAN Number </label>
              <input
                type="text"
                class="form-control"
                placeholder="Input 10 digit Pan Number"
                name="pan"
                v-model="panno"
                onKeyPress="if(this.value.length==10) return false;"
                v-on:keyup="validateNuber"
              />
              <i
                class="fa fa-info-circle input-tooltip color-blue"
                delay="0"
                data-toggle="tooltip"
                data-placement="top"
                title="Permanent account number is requred"
              ></i>
            
                <div v-if="showinvalid" class="error-message color-red sub-heading">Enter 10 Digit PAN Number.</div>

            </div>
            <div class="col-md-5 col-sm-6 col-xs-12 form-group">
                 <label for="pan" maxlength="10" class="color-white">Emi Delay in last 6 months <span class="color-red">*</span></label>
              <select name="civil_score" v-model="emi_delay_last_6_months" id="credit-score" class="form-control"
                v-model.trim="$v.emi_delay_last_6_months.$model"
                :class="{'is-invalid': validationStatus($v.emi_delay_last_6_months)}"
                >
                  <option value="">Select An Option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                </select>

            </div>
            <div class="col-md-5 col-sm-6 col-xs-12 form-group">
                 <label for="pan" maxlength="10" class="color-white">Total Credit card Outstanding <span class="color-red">*</span></label>
              <input
                type="number"
                class="form-control"
                placeholder="Input Total Credit card Outstanding"
                name="total_credit_card_out_standing"
                v-model="total_credit_card_out_standing"
                 onKeyPress="if(this.value.length==8) return false;"
                 v-on:keyup="validateNuber"
              />
          
            
                

            </div>

              <div class="col-12 form-group mgt-15">
                <router-link to="/debt-salaried/personal-detail">
                  <button type="button" class="btn button-dark-blue form-button d-flex-inline justify-content-center align-items-center color-white bg-blue mgr-15">Previous</button>
                </router-link>
                  <button v-on:click="submit" type="submit" class="btn salPLTDSCont form-button button-blue d-flex-inline justify-content-center align-items-center color-white bg-blue">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12">
      <ApplyFeature v-bind:list="list" />
      </div>
    </div>
    <OtherPages page="personal-loan"/>
    <Footer
    content="For instance, if Megha applies for a Personal loan of INR 3 Lacs and get the loan sanctioned at the rate of 11.99% with a tenure range of 5 years, in such a case her EMI would be INR 6,672 and complete repayment amount till the end of 5 years tenure would be INR 4,00,309 (i.e 6,672 EMI * 60 months), wherein the total interest amount is INR 1,00,309 along with principal repayment of INR 3,00,000. Few additional charges like those of minimal one-time processing fee ranging from 0.5% to 3% of loan amount, pre-payment penalty on an earlier foreclosure of loan ranging from 1% to 5% may also apply. T& C apply."
    />
  </div>
  </template>

  <script>
  import { required,numeric,minLength,maxLength } from 'vuelidate/lib/validators'
  import ApplyProgress from '../../sub-components/ApplyProgress';
  import ApplyFeature from '../../sub-components/ApplyFeature';
  import ApplyReview from '../../sub-components/ApplyReview';
  import Header from '../../sub-components/Header';
  import Footer from '../../sub-components/FooterDebt';
  import OtherPages from '../../sub-components/OtherPagesDebt';
  import axios from '../../../axios-auth'
  import { ToWords } from 'to-words';
  const toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
    }
  });
  export default {
    name: "TransactionDetailSalaried",
    data:function()
    {
        return{
          showinvalid:false,
          words_str:'', 
          flg:false,
          banks:[],
          panno:null,
          salary_account_name:"",
          civil_score:'Below 650',
          mode_of_salary:"Cash",
          ownership:"",
          emi_delay_last_6_months:"",
          total_credit_card_out_standing:"",
        salariedObj:{
        "current_city_other":'',//
        "loan_amount_required":localStorage.getItem("loan_amount_required"),//
        "mobile_number":localStorage.getItem("mobile_number"),//
        "net_salary_all_deductions":localStorage.getItem("net_salary_all_deductions"),//
        "any_loans_running_emi_monthly":localStorage.getItem("any_loans_running_emi_monthly"),//
        "civil_score":localStorage.getItem("civil_score"),///
        "mini_introduction":'Debt Consolidate',
        "company_type":"",
        "current_city_of_residence":localStorage.getItem("current_city_other"),//
        "current_company_name":localStorage.getItem("current_company_no")==''?'':localStorage.getItem("current_company_no")*1,///
        "date_of_birth":localStorage.getItem("date_of_birth"),///
        "email":localStorage.getItem("email"),///
        "joining_date_in_current_company":localStorage.getItem("joining_date_in_current_company"),///
        "mode_of_salary":localStorage.getItem("mode_of_salary"),///
        "name":localStorage.getItem("name"),///
        "other_company_name":localStorage.getItem("current_company_name"),///
        "panno":localStorage.getItem("panno"),//
        "salary_account_name":localStorage.getItem("salary_account_name")==''?'':localStorage.getItem("salary_account_name")*1,
        "total_work_experience":'3-5',///
        "residence_ownership":localStorage.getItem("ownership"),
        "source":localStorage.getItem('source'),
        "emi_delay_last_6_months" : "",
        "total_credit_card_out_standing" : ""
        },
          list:[
          {
            "title":"Debt Consolidation Starting at 10.50%",
            "new":false
          },
          {
            "title":"Club Loans & Credit Cards upto 50 Lacs",
            "new":false
          },
          {
            "title":"Make Single Emi within 48 hrs",
            "new":true
          },
          {
            "title":"No Bank Visits required",
            "new":true
          },
          {
            "title":"Cashback upto Rs 5,000",
            "new":true
          },
          {
            "title":"Lower Emi Burden with Lowest ROI",
            "new":true
          },
          {
            "title":"Free & Quick Service",
            "new":false
          },
          {
            "title":"Servicing Pan India Cities",
            "new":false
          },
          {
            "title":"Dedicated Loan Professional",
            "new":true
          },
          {
            "title":"Increase Disposable Income",
            "new":true
          },
          {
            "title":"Remember Only One Emi Date!",
            "new":true
          },
        ],
        }

    },
    mounted ()
    { 
     /* if(localStorage.getItem("name")==null){
            this.$router.push('/debt-salaried/personal-detail');
      }*/
      this.civil_score = localStorage.getItem("civil_score") ? localStorage.getItem("civil_score") : "";
      this.any_loans_running_emi_monthly=localStorage.getItem("any_loans_running_emi_monthly") ? localStorage.getItem("any_loans_running_emi_monthly") : "";
      this.panno=localStorage.getItem("panno") ? localStorage.getItem("panno") : null;
      this.mode_of_salary=localStorage.getItem("mode_of_salary") ? localStorage.getItem("mode_of_salary") : "";
      this.ownership=localStorage.getItem("ownership") ? localStorage.getItem("ownership") : "";
      this.salary_account_name=localStorage.getItem("salary_account_name") ? localStorage.getItem("salary_account_name") : "";
      this.fetchBankData();
      this.words();
    },
    components: {
      ApplyProgress,
      ApplyFeature,
      ApplyReview,
      Header,
      Footer,
      OtherPages,
    },
    validations: {
          civil_score: {required},
          any_loans_running_emi_monthly: {required,numeric,maxLength:maxLength(7)},
          mode_of_salary: {required},
          ownership:{required},
          emi_delay_last_6_months:{required},

          // salary_account_name:{required},
      },
          methods: {
              onlyNumberKey(evt) {
              
            // Only ASCII character in that range allowed
            var ASCIICode = (evt.which) ? evt.which : evt.keyCode
            if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
                return false;
            return true;
        },
              validateNuber:function(){
              if(this.panno!=""){
              if(this.panno.length < 10){
                this.showinvalid = true
              }else{
               this.showinvalid = false
              }
              }else{
                this.showinvalid = false
              }
              },
              validationStatus: function(validation) {
              return typeof validation != "undefined" ? validation.$error : false;
              },
          submit: function() {
              this.flg=true;
              this.$v.$touch();
              console.log(this.mode_of_salary=='Direct Bank' && this.salary_account_name=='',this.mode_of_salary=='Direct Bank' , this.salary_account_name=='');
              if (this.mode_of_salary=='Direct Bank' && this.salary_account_name=='')return;
              console.log('wd');
              if (this.$v.$pendding || this.$v.$error) return;
              
              
              this.salariedObj.residence_ownership = this.ownership
              this.salariedObj.salary_account_name = this.salary_account_name
              this.salariedObj.civil_score = this.civil_score
              this.salariedObj.mode_of_salary = this.mode_of_salary
              this.salariedObj.emi_delay_last_6_months = this.emi_delay_last_6_months
              this.salariedObj.total_credit_card_out_standing = this.total_credit_card_out_standing
              this.salariedObj.panno = this.panno

              localStorage.setItem("ownership",this.ownership);
              localStorage.setItem("panno",this.panno);
              localStorage.setItem("mode_of_salary",this.mode_of_salary);
              localStorage.setItem("civil_score",this.civil_score);
              localStorage.setItem("emi_delay_last_6_months",this.emi_delay_last_6_months);
              localStorage.setItem("salary_account_name",this.salary_account_name==''?'':this.salary_account_name*1);

              axios.put("/debt-personal-loan/"+localStorage.getItem("debt_response_id"),{...this.salariedObj })
              .then((res) =>
              {
                  this.$router.push('/debt-salaried/thanks');
              }).catch((error) => { 
                alert("oops something went wrong")
          })


          },
          fetchBankData:function(){
            axios.get('/salaried-bank-account').then((response)=>{
              this.banks = response.data
              // console.log(response)
            }).catch((err)=>{
              console.log(err)
            });
          },
          reset_salary_account_name:function(){
            console.log('reset');
            if (this.mode_of_salary!='Direct Bank'){
              this.salary_account_name='';
            }
          },
          words:function(){

            if (this.any_loans_running_emi_monthly.match("[0-9]+")==this.any_loans_running_emi_monthly){

            this.words_str=toWords.convert(this.any_loans_running_emi_monthly)}
            if(this.any_loans_running_emi_monthly=='' || this.any_loans_running_emi_monthly==null){
              console.log(this.any_loans_running_emi_monthly);
              this.words_str=''
            }
          },

        },//methods
  };
  </script>
<style scoped>
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}
</style>
<template>
<div class="col-md-12 padttop">

  <ol>
  <li>Instant Loan Eligibility in 2 mins</li>
  <li>Find Lowest rate and Best Deals!</li>
  <li>Compare & Choose from Top Banks</li>
</ol>  
  
</div>
</template>

<script>
export default {
  name: 'ApplyReview',
  components: {
  }

}
</script>
<style scoped>
.padttop{
  margin-top: 45px;
}
img{
    width: 20px;
}
ul{
    padding: 0px;
    margin-bottom: 8px;
    line-height: 2;
}
ul.b {
  font-size: 22px !important;
  color : white;
}
ol {
  counter-reset: list;
   font-size: 18px !important;
  color : white;
  line-height: 2;
}
ol > li {
  list-style: none;
  
}
ol > li:before {
  counter-increment: list;
}

</style>

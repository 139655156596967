var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"progress-container"},[_c('ul',{staticClass:"list list-inline progress-list"},[_c('li',{staticClass:"list-inline-item",class:{ 
        'active': _vm.$route.path == '/salaried/basic-detail' || _vm.$route.path == '/salaried/personal-detail' || _vm.$route.path == '/salaried/transaction-detail' || _vm.$route.path == '/salaried/offer-detail'
         ||_vm.$route.path == '/self-employed/basic-detail' || _vm.$route.path == '/self-employed/personal-detail' || _vm.$route.path == '/self-employed/transaction-detail' || _vm.$route.path == '/self-employed/offer-detail' ,
        }},[( _vm.$route.path == '/salaried/basic-detail' || _vm.$route.path == '/self-employed/basic-detail')?_c('i',{staticClass:"number-icon"},[_vm._v("1")]):_c('i',{staticClass:"fa fa-check number-icon"}),_c('p',{staticClass:"color-white"},[_vm._v("Basic Details")])]),_c('li',{staticClass:"list-inline-item",class:{ 
        'active': _vm.$route.path == '/salaried/personal-detail' || _vm.$route.path == '/salaried/transaction-detail' || _vm.$route.path == '/salaried/offer-detail'
         || _vm.$route.path == '/self-employed/personal-detail' || _vm.$route.path == '/self-employed/transaction-detail' || _vm.$route.path == '/self-employed/offer-detail' ,
        }},[( _vm.$route.path == '/salaried/personal-detail' || _vm.$route.path == '/self-employed/personal-detail')?_c('i',{staticClass:"number-icon"},[_vm._v("2")]):_c('i',{staticClass:"fa fa-check number-icon"}),_c('p',{staticClass:"color-white"},[_vm._v("Personal Details")])]),_c('li',{staticClass:"list-inline-item",class:{ 
        'active':_vm.$route.path == '/salaried/transaction-detail' || _vm.$route.path == '/salaried/offer-detail'
         || _vm.$route.path == '/self-employed/transaction-detail' || _vm.$route.path == '/self-employed/offer-detail' ,
        }},[( _vm.$route.path == '/salaried/transaction-detail' || _vm.$route.path == '/self-employed/transaction-detail')?_c('i',{staticClass:"number-icon"},[_vm._v("3")]):_c('i',{staticClass:"fa fa-check number-icon"}),_c('p',{staticClass:"color-white"},[_vm._v("Financial Details")])]),_c('li',{staticClass:"list-inline-item",class:{ 
        'active': _vm.$route.path == '/salaried/offer-detail' || _vm.$route.path == '/self-employed/offer-detail' ,
        }},[( _vm.$route.path == '/salaried/offer-detail' || _vm.$route.path == '/self-employed/offer-detail')?_c('i',{staticClass:"number-icon"},[_vm._v("4")]):_c('i',{staticClass:"fa fa-check number-icon"}),_c('p',{staticClass:"color-white"},[_vm._v("Get Offers")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }
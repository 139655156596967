<template>
  <div class="container-fluid">
    <Header />
    <div class="row bg-blue content-padding pdt-70 relative d-flex">
      <ApplyProgress />
      <div class="col-md-8 col-sm-12 col-xs-12">
        <h3 class="color-white roboto-bold mgb-30 heading">
          Personal Detail Self Employed
        </h3>
        <div class="row mgb-60">
          <form v-on:submit.prevent="submit">
            <div class="row">
              <div class="col-md-5 col-sm-6 col-xs-12 form-group">
                <label for="full-name" class="color-white"
                  >Full Name <span class="color-red">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  id="full-name"
                  placeholder="Name as per PAN Card"
                  name="fullName"
                   onKeyPress="if(this.value.length==40) return false;"
                  v-model.trim="$v.fullName.$model"
                  :class="{ 'is-invalid': validationStatus($v.fullName) }"
                />
                <div v-if="!$v.fullName.required&&flg" class="error-message color-red sub-heading">
                  Full name is required.
                </div>
              </div>
              <div class="col-md-5 col-sm-6 col-xs-12 form-group">
                <label for="email" class="color-white"
                  >Email <span class="color-red">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Input Email ID"
                  name="email"
                  id="email"
                  v-model.trim="$v.email.$model"
                  :class="{ 'is-invalid': validationStatus($v.email) }"
                />

                <i
                  class="fa fa-info-circle input-tooltip color-blue"
                  delay="0"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Ensure valid Email ID to receive timely communication for your application."
                ></i>

                <div v-if="!$v.email.required&&flg" class="error-message color-red sub-heading">
                  Email is required.
                </div>
                <div v-if="!$v.email.email" class="error-message color-red sub-heading">Enter Valid Email</div>

              </div>
              <div class="col-md-2 col-sm-12 col-xs-12 form-group"></div>
              <div class="col-md-5 col-sm-6 col-xs-12 form-group">
                <label for="date-of-birth" class="color-white"
                  >Date of Birth <span class="color-red">*</span></label
                >
                <datepicker id="dob" 
                  :bootstrap-styling="true" 
                  :open-date="new Date('1999-01-01')" 
                  v-model="dateofbirth" 
                  lang="en" 
                  type="month" 
                  placeholder="Please Select"
                  format="yyyy-MM-dd"
                  calendar-class="form-control"
                  v-model.trim="$v.dateOfBirth.$model"
                  :disabled-dates="state.disabledDates"
                :class="{'is-invalid': validationStatus($v.date_of_birth)}"
                   >
                   </datepicker>
                <!-- <input
                  type="date"
                  class="form-control"
                  id="date-of-birth"
                  placeholder="Date of Birth"
                  name="dateOfBirth"
                  v-mod  
                  v-model.trim="$v.dateOfBirth.$model"
                  :class="{ 'is-invalid': validationStatus($v.dateOfBirth) }"
                  :max="Date().split(' ')[3].toString()-21+'-12-31'"
                /> -->
                <div v-if="!$v.dateOfBirth.required&&flg" class="error-message color-red sub-heading">
                  Date of birth is required.
                </div>
              </div>
              <div class="col-md-5 col-sm-6 col-xs-12 form-group">
                <label for="pan" class="color-white"
                  >PAN Number <span class="color-red">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Input 10 digit Pan Number"
                  name="pan"
                   onKeyPress="if(this.value.length==10) return false;"
                  v-model.trim="$v.pan.$model"
                  :class="{ 'is-invalid': validationStatus($v.pan) }"
                  v-on:keyup="validateNuber"
                />
                <i
                  class="fa fa-info-circle input-tooltip color-blue"
                  delay="0"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Permanent account number is requred"
                ></i>
                <div v-if="!$v.pan.required&&flg" class="error-message color-red sub-heading">
                  PAN is required.
                </div>
                <div v-if="showinvalid" class="error-message color-red sub-heading">Enter 10 Digit PAN Number.</div>

              </div>
              <div class="col-md-2 col-sm-12 col-xs-12"></div>

              <div class="col-md-5 col-sm-6 col-xs-12 form-group">
                <label for="ownership" class="color-white"
                  >Ownership Status <span class="color-red">*</span></label
                >
                <select
                  name="ownership"
                  v-model="ownership"
                  id="ownership"
                  class="form-control"
                  v-model.trim="$v.ownership.$model"
                  :class="{ 'is-invalid': validationStatus($v.ownership) }"
                >
                <option value=null>Select An Option</option>
                  <option value="Owned">Owned</option>
                  <option value="Rented">Rented</option>
                </select>
                <div v-if="!$v.ownership.required&&flg" class="error-message color-red sub-heading">
                  Ownership status is required.
                </div>
              </div>

              <div class="col-md-5 col-sm-6 col-xs-12 form-group">
                <label for="business-life" class="color-white"
                  >How Old is Business*</label
                >
                <select
                  name="businessLife"
                  v-model="businessLife"
                  id="business-life"
                  class="form-control"
                  v-model.trim="$v.businessLife.$model"
                  :class="{ 'is-invalid': validationStatus($v.businessLife) }"
                >
                  <option value=null>Select An Option</option>
                  <option value="1">Less than 1 year</option>
                  <option value="2">1 Yr - 3 Yr</option>
                  <option value="3">3 Yr - 5 Yr</option>
                  <option value="10">More than 5 Yr</option>
                </select>
                <div v-if="!$v.businessLife.required&&flg" class="error-message color-red sub-heading">
                  How Old is Business is required.
                </div>
              </div>
              <div class="col-12 form-group mgt-15">
                <router-link to="/self-employed/basic-detail">
                  <button
                    type="button"
                    class="btn button-dark-blue form-button d-flex-inline justify-content-center align-items-center color-white bg-blue mgr-15"
                  >
                    Previous
                  </button>
                </router-link>
                <button
                  v-on:click="submit"
                  type="submit"
                  class="btn form-button button-blue d-flex-inline justify-content-center align-items-center color-white bg-blue"
                >
                  Continue
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12">
        <ApplyFeature v-bind:list="list" />
      </div>
    </div>
    <OtherPages page="business-loan" />
    <Footer
      content="For instance, if Rahul applies for a Business loan of INR 5 Lacs and get the loan sanctioned at the rate of 15.99% with a tenure range of 3 years, in such a case his EMI would be INR 17,576 and complete repayment amount till the end of 3 years tenure would be INR 6,32,738 (i.e 17,576 EMI * 36 months), wherein the total interest amount is INR 1,32,738 along with principal repayment of INR 5,00,000. Few additional charges like those of minimal one-time processing fee ranging from 1% to 3% of loan amount, pre-payment penalty on an earlier foreclosure of loan ranging from 1% to 5% may also apply. T& C apply."
    />
  </div>
</template>

<script>
import { required, minLength,email } from "vuelidate/lib/validators";
import ApplyProgress from "../../sub-components/ApplyProgress";
import ApplyFeature from "../../sub-components/ApplyFeature";
import ApplyReview from "../../sub-components/ApplyReview";
import Header from "../../sub-components/Header";
import Footer from "../../sub-components/Footer";
import OtherPages from '../../sub-components/OtherPageBL';
import Datepicker from 'vuejs-datepicker'

// import {self_employed_form} from '../globalVariableSalaried.js'
export default {
  name: "PersonalDetailSelfEmployed",
  data: function () {
    return {
       state : {
				disabledDates: {
					from: new Date(2000, 0, 1), // Disable all dates after specific date
				 }
			},
      showinvalid:false,
      businessLife: 1,
      flg:false,
      ownership: null,
      pan: null,
      dateOfBirth: '1999-07-22',
      email: null,
      fullName: null,
      self_employed_form: {},
       list:[
       {
            "title":"Business Loan Starting at 12.99%",
            "new":false
          },
          {
            "title":"Best Offer to choose from 20+ Banks",
            "new":false
          },
          {
            "title":"No Bank Visits required",
            "new":true
          },
          {
            "title":"Cashback upto Rs 5,000",
            "new":true
          },
          {
            "title":"Instant Paperless Approvals",
            "new":true
          },
          {
            "title":"Free & Quick Service",
            "new":false
          },
          {
            "title":"Servicing Pan India Cities",
            "new":false
          },
          {
            "title":"Dedicated Loan Professional",
            "new":true
          },
          {
            "title":"Money in Account in 24 hrs",
            "new":true
          },
        ],
    };
  },
  components: {
    ApplyProgress,
    ApplyFeature,
    ApplyReview,
    Header,
    Footer,
    OtherPages,
    Datepicker
  },
  validations: {
    ownership: { required },
    dateOfBirth: { required },
    pan: { required, minLength: minLength(10) },
    email: { required,email },
    fullName: { required },
    businessLife: { required },
  },
  mounted() {
    this.self_employed_form = localStorage.getItem("self_employed_form")
      ? JSON.parse(localStorage.getItem("self_employed_form"))
      : {};
    this.businessLife = this.self_employed_form.business_registration_years
      ? this.self_employed_form.business_registration_years
      : null;
    this.ownership = this.self_employed_form.office_ownership
      ? this.self_employed_form.office_ownership
      : null;
    this.pan = this.self_employed_form.panno
      ? this.self_employed_form.panno
      : null;
    this.dateOfBirth = this.self_employed_form.date_of_birth
      ? this.self_employed_form.date_of_birth
      : '1992-01-01';
    this.email = this.self_employed_form.email
      ? this.self_employed_form.email
      : null;
    this.fullName = this.self_employed_form.name
      ? this.self_employed_form.name
      : null;
  },
  methods: {
     validateNuber:function(){
        if(this.pan!=""){
        if(this.pan.length < 10){
            this.showinvalid = true
        }else{
          this.showinvalid = false
        }
        }else{
           this.showinvalid = false
        }
      },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    submit: function () {
      //console.log(this.loanAmount);
      this.flg=true;
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;

      this.self_employed_form.business_registration_years = this.businessLife;
      this.self_employed_form.office_ownership = this.ownership;
      this.self_employed_form.panno = this.pan;
      this.self_employed_form.date_of_birth = this.dateOfBirth;
      this.self_employed_form.email = this.email;
      this.self_employed_form.name = this.fullName;
      localStorage.setItem(
        "self_employed_form",
        JSON.stringify(this.self_employed_form)
      );

      this.$router.push("/self-employed/transaction-detail");
      // alert('Data Submit');
    },
  },
};
</script>
<style>

</style>

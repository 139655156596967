<template>
  <div>
    <div class="overflow-hidden sticky-top">
      <Header />
    </div>
    <div class="bg-image pdt-30">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="d-md-block d-none pdt-60 mb-5">
              <img src="/img/basic-details-1.svg" alt="" />
            </div>
          </div>

          <div class="col-md-6 min-height-90vh">
            <!-- heading -->
            <div class="mgb-30">
              <h1 class="color-blue">
                <span class="heading">Compare and Apply For</span> <br />
                <span class="big-heading">Personal Loan in 2 mins</span>
              </h1>
            </div>

            <!-- List -->
            <div>
              <ul class="features list">
                <li class="d-flex align-items-center list-heading">
                  Personal Loan Starting 10.40%
                </li>
                <li class="d-flex align-items-center list-heading">
                  Instant paperless approvals
                  <sup class="blink">New</sup>
                </li>
                <li class="d-flex align-items-center list-heading">
                  Free quick service
                </li>
                <li class="d-flex align-items-center list-heading">
                  10.4% intrest rate
                  <sup class="blink">New</sup>
                </li>
                <li class="d-flex align-items-center list-heading">
                  Money in Account within 24 hrs
                </li>
                <li class="d-flex align-items-center list-heading">
                  Cashback upto Rs 5,000
                  <sup class="blink">New</sup>
                </li>
                <li class="d-flex align-items-center list-heading">
                  Servicing Pan India Cities
                </li>
                <li class="d-flex align-items-center list-heading">
                  Easy debt consolidation
                  <sup class="blink">New</sup>
                </li>
              </ul>
            </div>

            <!-- Ratings -->
            <div class="mb-5">
              <div class="mb-2 ratings">
                <span class="star sub-heading fa fa-star checked"></span>
                <span class="star sub-heading fa fa-star checked"></span>
                <span class="star sub-heading fa fa-star checked"></span>
                <span class="star sub-heading fa fa-star checked"></span>
                <span class="star sub-heading fa fa-star-half-o"></span>
              </div>

              <div>
                <a href="" class="font-small">4.6/5 Rating 1488 Reviews</a>
              </div>
            </div>

            <!-- Work Profile -->
            <div>
              <a href="#" class="sub-heading">Work Profile</a>

              <form class="mt-4">
                <div class="form-row">
                  <div class="col-md-6 col-8">
                    <select class="form-control form-control-lg">
                      <option>Salaried</option>
                    </select>
                  </div>
                  <div class="col-md-3y col-4">
                    <a href="/basic-details" class="btn btn-dark btn-lg w-100"
                      >GET STARTED</a
                    >
                  </div>
                </div>
              </form>
            </div>

            <!-- Modal -->
            <div class="pb-3 pt-5">
              <hr />

              <div
                class="d-flex flex-md-row flex-column justify-content-between"
              >
                <div
                  data-toggle="modal"
                  data-target="#Features"
                  class="cursor-pointer mb-5"
                >
                  <div class="card align-items-center border-0 bg-transparent">
                    <div class="p-3">
                      <img
                        src="/img/benefits@2x.png"
                        class="icon-size-medium"
                        alt="..."
                      />
                    </div>
                    <div class="media-body">
                      <h5 class="mt-0 sub-heading">
                        Features & Benefits
                      </h5>
                    </div>
                  </div>
                </div>

                <div
                  data-toggle="modal"
                  data-target="#Features"
                  class="cursor-pointer mb-5"
                >
                  <div class="card align-items-center border-0 bg-transparent">
                    <div class="p-3">
                      <img
                        src="/img/eligibility.png"
                        class="icon-size-medium"
                        alt="..."
                      />
                    </div>
                    <div class="media-body">
                      <h5 class="mt-0 sub-heading">
                        Eligibility
                      </h5>
                    </div>
                  </div>
                </div>

                <div
                  data-toggle="modal"
                  data-target="#Features"
                  class="cursor-pointer mb-5"
                >
                  <div class="card align-items-center border-0 bg-transparent">
                    <div class="p-3">
                      <img
                        src="/img/document-required.png"
                        class="icon-size-medium"
                        alt="..."
                      />
                    </div>
                    <div class="media-body">
                      <h5 class="mt-0 sub-heading">
                        Documents Required
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              id="Features"
              tabindex="-1"
              role="dialog"
              aria-labelledby="FeaturesLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="heading mb-3">
                      Get to Choose the Best Lender for Your Business Loan Needs
                    </div>
                    <div class="sub-heading">
                      A business loan is one of the best remedies of modern
                      life's financial concern. Today almost everyone came
                      across cash crunch situation and financial requirement
                      that need fund at the moment, while one can gradually pay
                      them over the period of time, these requirement from
                      educationfor self or family. medical requirements, need to
                      travel or fullfill a long wishlist of a gadget and several
                      other requirements. While there are several ways to get
                      access to funds, asking friends and family may be a risky
                      proportion. Thus the best bet is to opt for business loan
                      from financial institutions
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Footer Text -->
      <div class="overflow-hidden">
        <Footer
          content="For instance, if Megha applies for a Personal loan of INR 3 Lacs and get the loan sanctioned at the rate of 11.99% with a tenure range of 5 years, in such a case her EMI would be INR 6,672 and complete repayment amount till the end of 5 years tenure would be INR 4,00,309 (i.e 6,672 EMI * 60 months), wherein the total interest amount is INR 1,00,309 along with principal repayment of INR 3,00,000. Few additional charges like those of minimal one-time processing fee ranging from 0.5% to 3% of loan amount, pre-payment penalty on an earlier foreclosure of loan ranging from 1% to 5% may also apply. T& C apply."
        />
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "../sub-components/Footer";
import Header from "../sub-components/Header";
export default {
  components: {
    Footer,
    Header,
  },
};
</script>

<style scoped>
.bg-image {
  background-image: url("/img/basic-details-banner.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}

.min-height-90vh {
  min-height: 90vh;
}

.color-blue {
  color: #064c95;
}

.ratings .star {
  color: gold;
}

.icon-size-medium {
  width: 50px;
}

.cursor-pointer {
  cursor: pointer;
}

ul {
  padding-left: 0px;
}
ul li {
  list-style: none;
}
ul li:not(:last-child) {
  margin-bottom: 15px;
}
ul li::before {
  content: "✓";
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-right: 10px;
  color: #4883ff;
}
sup {
  color: red;
  margin-left: 5px;
}

.banner-image {
  height: 100%;
  object-fit: contain;
  width: 100%;
}

.font-small {
  font-size: 10px;
}

.overflow-hidden {
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .bg-image {
    background-image: none !important;
  }
}

@media only screen and (max-width: 1200px) {
  ul li {
    font-size: 12px;
  }
}
</style>

<template>
  <div class="bg-blue">
    <div class="overflow-hidden">
      <Header />
    </div>
    <div class="container min-height-80vh">
      <div class="row">
        <div class="col-md-7">
          <!-- image -->
          <div class="d-md-block mb-5">
            <img src="/img/home1.jpg" class="w-100" alt="" />
          </div>

          <!-- features List -->
          <div class="d-flex flex-md-row flex-column">
            <div class="mr-md-5">
              <ul class="list">
                <li class="d-flex align-items-center list-heading">
                  <div class="color-white">
                    Personal Loan Starting at 10.40%
                  </div>
                </li>

                <li class="d-flex align-items-center list-heading">
                  <div class="color-white">10.4% interest rate</div>
                  <sup class="blink">New</sup>
                </li>

                <li class="d-flex align-items-center list-heading">
                  <div class="color-white">Money in Account in 24 hrs</div>
                </li>

                <li class="d-flex align-items-center list-heading">
                  <div class="color-white">Cashback upto Rs 5,000</div>
                  <sup class="blink">New</sup>
                </li>
              </ul>
            </div>

            <div>
              <ul class="list">
                <li class="d-flex align-items-center list-heading">
                  <div class="color-white">Instant Paperless Approvals</div>
                  <sup class="blink">New</sup>
                </li>

                <li class="d-flex align-items-center list-heading">
                  <div class="color-white">Free and Quick Service</div>
                </li>

                <li class="d-flex align-items-center list-heading">
                  <div class="color-white">Easy Debt Consolidation</div>
                  <sup class="blink">New</sup>
                </li>

                <li class="d-flex align-items-center list-heading">
                  <div class="color-white">Servicing Pan India Cities</div>
                </li>
              </ul>
            </div>
          </div>

          <hr class="bg-white mb-5" />

          <!-- Links -->
          <div class="d-flex flex-md-row flex-column justify-content-between">
            <div
              data-toggle="modal"
              data-target="#Features"
              class="cursor-pointer mb-5"
            >
              <div class="media align-items-center">
                <div class="bg-white mr-3 rounded-circle p-3">
                  <img
                    src="/img/benefits@2x.png"
                    class="icon-size-small"
                    alt="..."
                  />
                </div>
                <div class="media-body">
                  <h5 class="mt-0 color-white sub-heading">
                    Features & Benefits
                  </h5>
                </div>
              </div>
            </div>

            <div
              data-toggle="modal"
              data-target="#Features"
              class="cursor-pointer mb-5"
            >
              <div class="media align-items-center">
                <div class="bg-white mr-3 rounded-circle p-3">
                  <img
                    src="/img/eligibility.png"
                    class="icon-size-small"
                    alt="..."
                  />
                </div>
                <div class="media-body">
                  <h5 class="mt-0 color-white sub-heading">
                    Eligibility
                  </h5>
                </div>
              </div>
            </div>

            <div
              data-toggle="modal"
              data-target="#Features"
              class="cursor-pointer mb-5"
            >
              <div class="media align-items-center">
                <div class="bg-white mr-3 rounded-circle p-3">
                  <img
                    src="/img/document-required.png"
                    class="icon-size-small"
                    alt="..."
                  />
                </div>
                <div class="media-body">
                  <h5 class="mt-0 color-white sub-heading">
                    Documents Required
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-5 mb-5 pt-5">
          <!-- heading -->
          <div class="mgb-30">
            <h1 class="color-white">
              <span class="heading">Compare and Apply For</span> <br />
              <span class="big-heading">Personal Loan in 2 mins</span>
            </h1>
          </div>

          <!-- Ratings -->
          <div class="mb-5">
            <div class="mb-2 ratings">
              <span class="star sub-heading fa fa-star checked"></span>
              <span class="star sub-heading fa fa-star checked"></span>
              <span class="star sub-heading fa fa-star checked"></span>
              <span class="star sub-heading fa fa-star checked"></span>
              <span class="star sub-heading fa fa-star-half-o"></span>

              <span class="ml-3">
                <a href="" class="font-small color-white"
                  ><u>4.6/5 Rating 1488 Reviews</u></a
                >
              </span>
            </div>
          </div>

          <!-- Work Profile -->
          <div>
            <a href="#" class="sub-heading color-blue">Work Profile</a>

            <form class="mt-4">
              <div class="form-row">
                <div class="col-6">
                  <select class="form-control form-control-lg">
                    <option>Salaried</option>
                  </select>
                </div>
                <div class="col-6">
                  <a href="/basic-details-2" class="btn btn-color-blue btn-lg"
                    >GET STARTED</a
                  >
                </div>
              </div>
            </form>
          </div>

          <!-- Modal -->
          <div
            class="modal fade"
            id="Features"
            tabindex="-1"
            role="dialog"
            aria-labelledby="FeaturesLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="heading mb-3">
                    Get to Choose the Best Lender for Your Business Loan Needs
                  </div>
                  <div class="sub-heading">
                    A business loan is one of the best remedies of modern life's
                    financial concern. Today almost everyone came across cash
                    crunch situation and financial requirement that need fund at
                    the moment, while one can gradually pay them over the period
                    of time, these requirement from educationfor self or family.
                    medical requirements, need to travel or fullfill a long
                    wishlist of a gadget and several other requirements. While
                    there are several ways to get access to funds, asking
                    friends and family may be a risky proportion. Thus the best
                    bet is to opt for business loan from financial institutions
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer Text -->
    <div class="overflow-hidden">
      <Footer
        content="For instance, if Megha applies for a Personal loan of INR 3 Lacs and get the loan sanctioned at the rate of 11.99% with a tenure range of 5 years, in such a case her EMI would be INR 6,672 and complete repayment amount till the end of 5 years tenure would be INR 4,00,309 (i.e 6,672 EMI * 60 months), wherein the total interest amount is INR 1,00,309 along with principal repayment of INR 3,00,000. Few additional charges like those of minimal one-time processing fee ranging from 0.5% to 3% of loan amount, pre-payment penalty on an earlier foreclosure of loan ranging from 1% to 5% may also apply. T& C apply."
      />
    </div>
  </div>
</template>

<script>
import Footer from "../sub-components/Footer";
import Header from "../sub-components/Header";
export default {
  components: {
    Footer,
    Header,
  },
};
</script>

<style scoped>
.font-small {
  font-size: 10px;
}

.min-height-80vh {
  min-height: 80vh;
}

.overflow-hidden {
  overflow: hidden;
}

ul {
  padding-left: 0px;
}
ul li {
  list-style: none;
}
ul li:not(:last-child) {
  margin-bottom: 15px;
}
ul li::before {
  content: "";
  display: inline-block;
  height: 20px;
  width: 20px;
  background-image: url("../../assets/img/checked.svg");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}
sup {
  color: red;
  margin-left: 5px;
}

.ratings .star {
  color: gold;
}

.bg-blue {
  background: #142a4c;
}

.icon-size-small {
  width: 25px;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-color-blue {
  background: #426dfa;
  color: white;
}

@media only screen and (max-width: 1200px) {
  ul li {
    font-size: 12px;
  }
}
</style>

<template>
  

  <div class="row content-padding pd-30-0 bg-white">
    <!-- The Modal -->
<div class="modal" id="eligibilty">
  <div class="modal-dialog modal-lg" style="font-size:16px">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Eligibility of a Debt Consolidation Loan</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
       <div class="container-fluid">
    <div class="row bg-blue px-10">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-sm-8 col-8 d-flex justify-content-start align-items-center">
            <h3 class="heading color-white roboto-bold banner-heading">
              Eligibility of a Debt Consolidation Loan
            </h3>
          </div>
          <div class="col-md-4 col-sm-4 col-4 banner-image-section d-flex justify-content-end">
            <!-- <img src="../../assets/img/eligibility-banner.png" /> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="container">
        <h3 class="heading mgt-30">
          There are different eligibility criteria for Debt Consolidation product from various banks. 
          Few common eligibility criteria are as below:-
        </h3>
          <div class="mgb-30 col-md-6 list-container flex-column d-flex justify-content-center pt-5">
                    <ul class="list feature-list">
                        <li class="list-heading">Age limit: 23 to 52 years
                        </li>
                     <li class="list-heading">Employment: Salaried, Employed with an MNCs, public or private company</li>
                     <li class="list-heading">Minimum Salary: Rs 40000 per month</li>
                     <li class="list-heading">Credit Score above 680</li>
                     <li class="list-heading">No Emi Delays In Last 3 Months</li>
                     <li class="list-heading">APR Ranges from 10.50% to 17% p.a</li>
                     <li class="list-heading">Repayment Tenure ranges from 2 yrs to 7 yrs</li>
                    </ul>
                   
                </div>
        <!-- <div class="row mgt-30 mgb-30">
          <table class="table table-bordered">
            <thead>
              <tr class="bg-blue color-white">
                <th>Categories</th>
                <th>Eligibility</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Age</td>
                <td>23 to 58 years</td>
              </tr>
              <tr>
                <td>Employment</td>
                <td>
                  Salaried, Employed with an MNCs, public or private company
                </td>
              </tr>
              <tr>
                <td>APR Ranges</td>
                <td>from 10.40% to 19% p.a</td>
              </tr>
              <tr>
                <td>Minimum Salary</td>
                <td>RS 30000 per month</td>
              </tr>
              <tr>
                <td>Repayment Tenure ranges</td>
                <td>from 2 yrs to 7yrs</td>
              </tr>
              <tr>
                <td>Credit Score</td>
                <td>Above 650</td>
              </tr>
            </tbody>
          </table>
        </div> -->
      </div>
    </div>
    
  </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>


<div class="modal" id="feature">
  <div class="modal-dialog modal-lg" style="font-size:16px">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Features and Benefits</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
       <div class="container-fluid">
    <div class="row bg-blue px-10">
      <div class="container">
          <div class="row">
        <div class="col-md-8 col-sm-8 col-8 d-flex justify-content-start align-items-center">
          <h3 class="heading color-white roboto-bold banner-heading">Some Key Benefits of Debt Consolidation Loans</h3>
        </div>
        <div class="col-md-4 col-sm-4 col-4 banner-image-section d-flex justify-content-end">
          <!-- <img src="../../assets/img/benefits-banner.png" /> -->
        </div>
          </div>
      </div>
    </div>
    <div class="row bg-white">
        <div class="container">
            <div class="row">
                <div class="col-md-12 mgt-15">
                <h4 class="heading">1. Lower EMI</h4>
                <p class="sub-heading">
                 Credit Cards are high-cost debt instruments. The interest rates are several times that of a personal loan. It only starts to bother when the bills become too much to manage to result in collection calls. Repaying these dues every month eventually makes our net EMI outflow ridiculously high. A consolidated personal loan reduces this EMI burden by lowering the overall EMI outflow every month and making it more predictable to pay.
                </p>
                 
                </div>
                 <div class="col-md-12 mgt-15">
                <h4 class="heading">2. Quicker pay off</h4>
                <p class="sub-heading">
                  Once we consolidate all personal loans and credit cards, we may choose to pay them off earlier by preferring an earlier loan closure period. Even having the hope of a debt-free life is a liberating thought.

                </p>
                 
                </div>
                 <div class="col-md-12 mgt-15">
                <h4 class="heading">3. Manageable Loan Duration</h4>
                <p class="sub-heading">
                    The loan duration can be manageable by working with the new lender and creating the right loan amount and tenure, making paying off the consolidated loan comfortably.  

                </p>
                 
                </div>
                <div class="col-md-12 mgt-15">
                <h4 class="heading">4. Single Lender</h4>
                <p class="sub-heading">
                    Remembering the payment dates of multiple lenders is a nightmare, missing payments result in late payment fees and eventually spoils our credit score, reducing chances of getting personal loans in future. A single lender makes communication and payment easy.

                </p>
                 
                </div>
                <div class="col-md-12 mgt-15">
                <h4 class="heading">5. Can Improve Credit Score</h4>
                <p class="sub-heading">
                    As we know every lender inspects our credit score before lending . Hence via Debt consolidation, our multiple loans get structured into one loan and hence our credit utilisation limits improves which in long run helps in increasing our credit score.
                </p>
                 
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-md-12 mgt-10">
                    <h3 class="heading">Features and Benefits</h3>
                </div>
               
                <div class="mgb-30 col-md-6 list-container flex-column d-flex justify-content-center">
                    <ul class="list feature-list">
                        <li class="list-heading">Loan Upto 50 Lacs
                        </li>
                     <li class="list-heading">Digital Documentation</li>
                     <li class="list-heading">Best Offer to choose from 20+ Banks & NBFCs</li>
                     <li class="list-heading">LoansJagat Service is Absolutely Free of Cost for their Customers</li>
                     <li class="list-heading">Best Deal Guranteed</li>
                     <li class="list-heading">No Collateral or guarantor needed</li>
                     <li class="list-heading">Dedicated Loan Professional for profile based assessment.</li>
                     <li class="list-heading">Highest Approval Ratio</li>
                     <li class="list-heading">Maximum Loan Eligibility Offers</li>
                     <li class="list-heading">Cashback upto Rs. 5,000 On disbursal of your loan</li>
                    </ul>
                   
                </div>
            </div> -->
        </div>
    </div>
   
  </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>

<div class="modal" id="document_req">
  <div class="modal-dialog modal-lg" style="font-size:16px">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title"> Document Requirement For Debt Consolidation Loan</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
       <div class="container-fluid">
    <div class="row bg-blue px-10">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-sm-8 col-8 d-flex justify-content-start align-items-center">
            <h3 class="heading color-white roboto-bold banner-heading">
              Document Requirement For Debt Consolidation Loan
            </h3>
          </div>
          <div class="col-md-4 col-sm-4 col-4 banner-image-section d-flex justify-content-end">
            <!-- <img src="../../assets/img/document-banner.png" /> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row bg-white">
      <div class="container">
        <div class="row">
          <div class="col-md-12 mgt-15">
            <h3 class="heading">Debt Consolidation - Document Required</h3>
            <p class="sub-heading">
             The lender seek following documents from the Debt Consolidation applicants. These documents can be submitted either as hard copy by physically visiting the bank or by simple emailing the documents or uploading the scanned copy on the digital platform/website .
            </p>
          </div>
        </div>
        <div class="row">
         
         
          <div
            class="col-md-6 list-container flex-column d-flex justify-content-center pt-5"
          >
            <ul class="list feature-list">
              <li class="list-heading">Aadhar & PAN Card</li>
              <li class="list-heading">Current Address Proof</li>
              <li class="list-heading">Last Salary Slips</li>
              <li class="list-heading">Latest Bank Statement</li>
              <li class="list-heading">Repayment Schedules Of Existing Loans</li>

            </ul>
          
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <Footer />
    </div> -->
  </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>
    <div
      class=" other-page col-md-2 col-sm-4 col-xl-4 d-flex flex-column align-items-center"
    >
    <a href="#"  class="pagelink d-flex flex-column justify-content-center align-items-center" data-toggle="modal" data-target="#feature">
      <!-- <router-link
        class="pagelink d-flex flex-column justify-content-center align-items-center"
        v-bind:to="`/${page}/benefits`"
        
      > -->
        <img src="../../assets/img/benefits@2x.png" alt="Benifits" />
        <p class="mgt-15 color-black sub-heading">Features and Benefits</p>
      <!-- </router-link> -->
    </a>
    </div>
    <div
      class=" other-page col-md-8 col-sm-4 col-xl-4 d-flex flex-column align-items-center"
    >
        <a href="#"  class="pagelink d-flex flex-column justify-content-center align-items-center" data-toggle="modal" data-target="#eligibilty">

      <!-- <router-link
        class="pagelink d-flex flex-column justify-content-center align-items-center"
        v-bind:to="`/${page}/eligibility`"
      > -->
        <img src="../../assets/img/eligibility.png" alt="Eligibility" />
        <p class="mgt-15 color-black sub-heading">Eligibility</p>
        </a>
      <!-- </router-link> -->
    </div>
    <div
      class="other-page col-md-2 col-sm-4 col-xl-4 d-flex flex-column align-items-center"
    >
        <a href="#"  class="pagelink d-flex flex-column justify-content-center align-items-center" data-toggle="modal" data-target="#document_req">

      <!-- <router-link
        class="pagelink d-flex flex-column justify-content-center align-items-center"
        v-bind:to="`/${page}/document-requirements`"
      > -->
        <img
          src="../../assets/img/document-required.png"
          alt="Document Required"
        />
        <p class="mgt-15 color-black sub-heading">Document Required</p>
        </a>
      <!-- </router-link> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "OtherPages",
  components: {},
  props: ["page"],
};
</script>

<style scoped>
.col-md-2 img,
.col-md-8 img {
  background-color: #fff;
  padding: 10px;
  width: 70px;
  box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 0.4);
}
</style>

<template>
    <div>
            <ThankU />
    </div>
</template>
<script>
import ThankU from '../../sub-components/debtThanks';

export default{
components: {
    ThankU
  },
}
</script>